import axios from "axios";
import Vue from "vue";

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        principals: [],
        lstPrincipalAccess: [],
        principalAccess: {
            principalCode: '',
            name: '',
            email: '',
            reportAccess: '',
        },
        principal: {
            principalCode: '',
            principalName: '',
            classification: '',
            address: '',
            telephone: '',
            mobileNumber: '',
            tin: '',
            listEmailInvoiceListing: [],
            listEmailStockOnHand: [],
            listEmailDeliveryMonitoring: [],
            listEmailDetailedAging: [],
            website: '',
            groupCode: '',
            creditTerms: '',
            isActive: true,
        },
        filters: {
            principalCode: '',
            principalName: ''
        }
    },
    mutations: {
        SET_Principals(state, data) {
            state.principals = data;
        },
        RESET_Principals(state) {
            state.principals = [];
        },
        BIND_Principal(state, principal) {
            state.principal = principal
        },
        RESET_Principal(state) {
            state.principal = {
                principalCode: '',
                principalName: '',
                classification: '',
                address: '',
                telephone: '',
                mobileNumber: '',
                tin: '',
                listEmailInvoiceListing: [],
                listEmailStockOnHand: [],
                listEmailDeliveryMonitoring: [],
                listEmailDetailedAging: [],
                website: '',
                groupCode: '',
                creditTerms: '',
                isActive: true,
            }
        },
        UPDATE_Principal(state, data) {
            var index = state.principals.findIndex((obj => obj.principalCode == data.principalCode));

            if (index >= 0) {
                Vue.set(state.principals, index, data);
            } else {
                state.principals.push(data);
            }
        },
        REMOVE_Principal(state, data) {
            var index = state.principals.findIndex((obj => obj.principalCode == data.principalCode));

            if (index >= 0) {
                state.principals.splice(index, 1);
            }
        },
        SET_LstPrincipalAccess(state, data) {
            state.lstPrincipalAccess = data
        },
        RESET_LstPrincipalAccess(state) {
            state.lstPrincipalAccess = []
        },
        RESET_PrincipalAccess(state) {
            state.principalAccess = {
                principalCode: '',
                name: '',
                email: '',
                reportAccess: '',
            }
        },
        UPDATE_PrincipalAccess(state, data) {
            var index = state.lstPrincipalAccess.findIndex((obj => obj.email == data.email));

            if (index >= 0) {
                Vue.set(state.lstPrincipalAccess, index, data);
            } else {
                state.lstPrincipalAccess.push(data);
            }
        },
        REMOVE_PrincipalAccess(state, email) {
            var index = state.lstPrincipalAccess.findIndex((obj => obj.email == email));

            if (index >= 0) {
                state.lstPrincipalAccess.splice(index, 1);
            }
        },
    },
    getters: {
        filters(state) {
            return state.filters
        },
        principal(state) {
            return state.principal
        },
        principalAccess(state) {
            return state.principalAccess
        }
    },
    actions: {
        GetPrincipals({ getters, commit, rootGetters }) {
            var filter = getters.filters;
            commit('RESET_Principals');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Principal/code?principalcode=" + filter.principalCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('SET_Principals', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetPrincipalAccess({ commit, rootGetters }, principalCode) {
            commit('RESET_LstPrincipalAccess');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Principal/access?principalcode=" + principalCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('SET_LstPrincipalAccess', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        AddPrincipal({ getters, commit, rootGetters }) {
            var principal = getters.principal;

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Principal", principal, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('UPDATE_Principal', response.data)
                        commit('RESET_Principal');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        ResetPrincipalAccess({ commit, rootGetters }, email) {
            return new Promise((resolve, reject) => {
                instance
                    .put("api/Principal/access/reset/password?email=" + email, '', {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('RESET_PrincipalAccess');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        DeletePrincipalAccess({ commit, rootGetters }, email) {
            return new Promise((resolve, reject) => {
                instance
                    .delete("api/Principal/access?email=" + email, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('REMOVE_PrincipalAccess', email)
                        commit('RESET_PrincipalAccess');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        AddPrincipalAccess({ getters, commit, rootGetters }) {
            var principalAccess = getters.principalAccess;

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Principal/access", principalAccess, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('UPDATE_PrincipalAccess', response.data)
                        commit('RESET_PrincipalAccess');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        EditPrincipalAccess({ getters, commit, rootGetters }) {
            var principalAccess = getters.principalAccess;

            return new Promise((resolve, reject) => {
                instance
                    .put("api/Principal/access", principalAccess, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('UPDATE_PrincipalAccess', response.data)
                        commit('RESET_PrincipalAccess');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        EditPrincipal({ getters, commit, rootGetters }) {
            var principal = getters.principal;

            return new Promise((resolve, reject) => {
                instance
                    .put("api/Principal/code/" + principal.principalCode, principal, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('UPDATE_Principal', response.data)
                        commit('RESET_Principal');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        DeletePrincipal({ getters, commit, rootGetters }) {
            var principal = getters.principal;

            return new Promise((resolve, reject) => {
                instance
                    .delete("api/Principal/code/" + principal.principalCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('REMOVE_Principal', principal)
                        commit('RESET_Principal');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        UploadPrincipal({ commit, rootGetters }, file) {
            let formData = new FormData();
            formData.append('file', file);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/principal/batch", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        ResetPrincipal({ commit }) {
            commit('RESET_Principal');
        },
        BindPrincipal({ commit }, principal) {
            commit('BIND_Principal', principal);
        }
    },
    modules: {},
};