<template>
  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div
      class="
        text-center
        navbar-brand-wrapper
        d-flex
        align-items-center
        justify-content-center
      "
    >
      <a class="navbar-brand brand-logo mr-5" href="/">
        <img src="../../../assets/logo_wide.png" class="mr-2" alt="logo" />
      </a>
      <a class="navbar-brand brand-logo-mini" href="/">
        <img src="../../../assets/logo.png" alt="logo" />
      </a>
      <button
        class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
        type="button"
        data-toggle="offcanvas"
      >
        <span class="icon-menu"></span>
      </button>
    </div>
    <div
      class="navbar-menu-wrapper d-flex align-items-center justify-content-end"
    >
      <button
        class="navbar-toggler navbar-toggler align-self-center"
        type="button"
        data-toggle="minimize"
      >
        <span class="icon-menu"></span>
      </button>
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a
            class="nav-link dropdown-toggle user-avatar"
            href="#"
            data-toggle="dropdown"
            id="profileDropdown"
          >
            <lottie-animation
              style="height: 50px"
              :loop="true"
              :animationData="require('@/assets/lottie/profile.json')"
            />
          </a>
          <div
            class="dropdown-menu dropdown-menu-right navbar-dropdown"
            aria-labelledby="profileDropdown"
          >
            <div class="user-information">
              <span class="user-initial-avatar">{{
                admin.name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")
              }}</span>
              <h4 class="mt-3">{{ admin.name }}</h4>
              <p>{{ admin.email }}</p>
              <a href="#">Edit Profile</a>
            </div>
            <hr style="width: 80%" />
            <a class="dropdown-item" href="/user">
              <i class="icon-head text-primary"></i>
              User Management
            </a>
            <a class="dropdown-item" @click="settings">
              <i class="ti-settings text-primary"></i>
              Settings
            </a>
            <a class="dropdown-item" @click="logout">
              <i class="ti-power-off text-primary"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.info,
    }),
  },
  methods: {
    logout: async function () {
      await this.$store.dispatch("deleteToken");
      window.location.href = "/";
    },
    settings: function () {
      this.$toast.open({
        message: "This feature is not yet implemented.",
        type: "info",
      });
    },
  },
};
</script>

<style>
</style>