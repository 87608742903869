<template>
  <div class="customer-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">District Manager Maintenance</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <label>District Manager Code:</label>
                      <input type="text" class="form-control" placeholder="DM Code" v-model="filter.dmCode"
                        :disabled="isSearching" />
                    </div>
                    <div class="col">
                      <p style="margin-bottom: 0.3rem">&nbsp;</p>
                      <button type="button" class="btn btn-primary" @click="searchFilter" :disabled="isSearching">
                        <i class="fas fa-search"></i>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-right">
                  <p style="margin-bottom: 0rem">&nbsp;</p>
                  <button type="button" class="btn btn-primary btn-sm-block mt-1">
                    <i class="fas fa-download"></i>
                    Export District Manager
                  </button>
                  <button type="button" class="btn btn-primary btn-sm-block mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                    :disabled="isSearching" @click="addNewDM">
                    <i class="fas fa-user-plus"></i>
                    Add District Manager
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>DM Code</th>
                    <th>DM Name</th>
                    <th>Designation</th>
                    <th>Agents</th>
                    <th>Email/s</th>
                    <th class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="districtsPageItems.length == 0 && !isSearching">
                    <td colspan="10">
                      <div>
                        <lottie-animation style="height: 500px" :loop="true" :animationData="require('@/assets/lottie/no-data.json')
                        " />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="isSearching">
                    <td colspan="10">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(district, i) in districtsPageItems" :key="i">
                    <td>{{ district.dmCode }}</td>
                    <td>{{ district.dmName }}</td>
                    <td>{{ district.designation }}</td>
                    <td>
                      <a href="#" @click="selectDMAgents(district.dmCode)">
                        Agents
                      </a>
                    </td>
                    <td>{{ district.listEmail.toString() }}</td>
                    <td class="text-center">
                      <a href="#" @click="showUserAccess(district.dmCode)">
                        Users
                      </a>
                      |
                      <a href="#" @click="selectDM(district)"> Edit </a>
                      |
                      <a href="#" @click="deleteDM(district)">Delete</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination v-if="districtsPageItems.length != 0" v-model="currentPage" :total-rows="rows"
              :per-page="pageSize" aria-controls="my-table" @change="paginationChanged"
              style="float: right; margin-top: 20px"></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="dmModalShow" size="xl" title="DM Details" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtDMCode" ref="modalDMDetails"> DM Code </label>
              <input type="text" class="form-control" id="txtDMCode" placeholder="DM Code" :class="{
                        'is-invalid': $v.district.dmCode.$error,
                      }" v-model="$v.district.dmCode.$model" :disabled="isEdit" />
              <div class="form-error" v-if="$v.district.dmCode.$error">
                DM Code is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtDesignation" ref="modalDMDetails">
                Designation
              </label>
              <input type="text" class="form-control" id="txtDesignation" placeholder="Designation" :class="{
                        'is-invalid': $v.district.designation.$error,
                      }" v-model="$v.district.designation.$model" />
              <div class="form-error" v-if="$v.district.designation.$error">
                Designation is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtDMName" ref="modalDMDetails"> DM Name </label>
              <input type="text" class="form-control" id="txtDMName" placeholder="DM Name" :class="{
                        'is-invalid': $v.district.dmName.$error,
                      }" v-model="$v.district.dmName.$model" />
              <div class="form-error" v-if="$v.district.dmName.$error">
                DM Name is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtEmails">Emails</label>
              <multiselect v-model="listEmail" tag-placeholder="Add this email" placeholder="Add email" label="name"
                track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addEmailTag"
                @remove="removeTag" :class="{
                        'is-invalid': $v.district.listEmail.$error,
                      }"></multiselect>
              <div class="form-error" v-if="$v.district.listEmail.$error">
                Emails is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="submitDistrict">
              Submit
            </button>
            <button type="button" class="btn btn-outline-light" @click="closeModal">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal v-model="dmAgentsModalShow" size="md" title="DM Agents" hide-footer no-close-on-backdrop>
      <p>
        DM Code: <b>{{ dmCode }}</b>
      </p>
      <hr />
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Agent Code</th>
            <th>Agent Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="districtAgents.length == 0">
            <td colspan="2">
              <div>
                <lottie-animation style="height: 500px" :loop="true"
                  :animationData="require('@/assets/lottie/no-data.json')" />
              </div>
            </td>
          </tr>
          <tr v-for="(dm, i) in districtAgents" :key="i">
            <td>{{ dm.agentCode }}</td>
            <td>{{ dm.agentName }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <b-sidebar id="sidebar-backdrop" backdrop shadow right width="1000px" no-header-close v-model="showSidebar"
      @change="changeSidebarStatus">
      <div class="px-3 py-3">
        <div class="row">
          <div class="col">
            <h3><strong>DM User Access</strong></h3>
          </div>
          <div class="col text-right">
            <button type="button" class="btn btn-primary mr-2" @click="addDMAccessModalShow = true">
              Add User
            </button>
          </div>
        </div>

        <hr style="opacity: 0.08" />
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Report Access</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dm, i) in lstDMAccess" :key="i">
              <td>{{ dm.name }}</td>
              <td>{{ dm.email }}</td>
              <td>{{ dm.reportAccess }}</td>
              <td>
                {{
                        new Date(dm.dateCreated).toLocaleDateString('en-US', {
                          month: 'short',
                          day: "numeric",
                          year: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                        })
                      }}
              </td>
              <td>
                <a href="#" @click="
                      resetDMAccess(dm.email);
                      isEdit = true;
                      ">
                  Reset
                </a>
                |
                <a href="#" @click="deleteDMAccess(dm.email)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>

    <!-- add dm access modal -->
    <b-modal v-model="addDMAccessModalShow" size="md" title="Add DM Access" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <p>
              DM Code:
              <strong>{{ dmAccess.dmCode }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Name</label>
              <input class="form-control" type="text" v-model="dmAccess.name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Email</label>
              <input class="form-control" type="text" v-model="dmAccess.email" />
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Report Access</label>
              <select
                class="form-control"
                v-model="dmAccess.reportAccess"
              >
                <option value="Delivery Monitoring">Delivery Monitoring</option>
                <option value="Stock On Hand">Stock On Hand</option>
                <option value="Sales Report">Sales Report</option>
              </select>
            </div>
          </div>
        </div> -->
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="AddDMAccess">
              Submit
            </button>
            <button type="button" class="btn btn-outline-light" @click="
                        addDMAccessModalShow = !addDMAccessModalShow
                        ">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      addDMAccessModalShow: false,
      showSidebar: false,
      file: null,
      districtsPageItems: [],
      options: [],
      dmModalShow: false,
      dmAgentsModalShow: false,
      pageSize: 50,
      currentPage: 1,
      isSearching: false,
      isDownloading: false,
      isEdit: false,
      listEmail: [],
      dmCode: "",
    };
  },
  validations: {
    district: {
      dmCode: {
        required,
      },
      dmName: {
        required,
      },
      designation: {
        required,
      },
      listEmail: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      districts: (state) => state.districtController.districts,
      district: (state) => state.districtController.district,
      districtAgents: (state) => state.districtController.districtAgents,
      filter: (state) => state.districtController.filter,
      lstDMAccess: (state) => state.districtController.lstDMAccess,
      dmAccess: (state) => state.districtController.dmAccess,
    }),
    rows() {
      return this.districts.length;
    },
  },
  methods: {
    ...mapMutations({
      setDistrict: "districtController/SET_District",
      resetDistrict: "districtController/RESET_District",
    }),
    showUserAccess: async function (dmCode) {
      await this.$store.dispatch("showOverlay", true);
      var result = await this.$store
        .dispatch("districtController/GetDMAccess", dmCode)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data,
            type: "error",
          });
        });

      if (result) {
        await this.$store.dispatch("showOverlay", false);
        this.dmAccess.dmCode = dmCode;
        this.showSidebar = true;
      }
    },
    changeSidebarStatus: function (value) {
      this.showSidebar = value;
    },
    AddDMAccess: async function () {
      await this.$store.dispatch("showOverlay", true);
      var result = await this.$store
        .dispatch("districtController/AddDMAccess")
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data,
            type: "error",
          });
        });

      if (result) {
        await this.$store.dispatch("showOverlay", false);
        this.addDMAccessModalShow = false;
        this.$toast.open({
          message: "DM user access has been successfully added.",
          type: "success",
        });
      }
    },

    deleteDMAccess: async function (email) {
      if (confirm("Are you sure you want to delete this dm access?")) {
        await this.$store.dispatch("showOverlay", true);
        var result = await this.$store
          .dispatch("districtController/DeleteDMAccess", email)
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });

        if (result) {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: "DM user access has been successfully deleted.",
            type: "success",
          });
        }
      }
    },
    resetDMAccess: async function (email) {
      if (confirm("Are you sure you want to reset password?")) {
        await this.$store.dispatch("showOverlay", true);
        var result = await this.$store
          .dispatch("districtController/ResetDMAccess", email)
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });

        if (result) {
          await this.$store.dispatch("showOverlay", false);
          this.addDMAccessModalShow = false;
          this.$toast.open({
            message: "DM user access has been successfully reset.",
            type: "success",
          });
        }
      }
    },
    addNewDM: async function name() {
      this.isEdit = false;
      this.listEmail = [];
      await this.resetDistrict();
      this.dmModalShow = !this.dmModalShow;
    },
    addEmailTag: function (email) {
      const tag = {
        name: email,
        code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };

      this.options.push(tag);
      this.listEmail.push(tag);
    },
    removeTag: function (removedOption) {
      for (var i = 0; i < this.district.listEmail.length; i++) {
        var obj = this.district.listEmail[i];

        if (obj == removedOption.name) {
          this.district.listEmail.splice(i, 1);
        }
      }
    },
    deleteDM: async function (district) {
      await this.setDistrict(district);
      var self = this;
      self.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete " + district.dmCode + "?",
          {
            title: "Delete DM",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await self.$store.dispatch("showOverlay", true);

            var result = await self.$store
              .dispatch("districtController/DeleteDistrict")
              .catch(async (error) => {
                await self.$store.dispatch("showOverlay", false);
              });

            if (result) {
              await self.searchFilter();
              await self.$store.dispatch("showOverlay", false);
            }
          }
        })
        .catch((err) => { });
    },
    removeEmailTag: function (removedOption) {
      for (var i = 0; i < this.district.listEmail.length; i++) {
        var obj = this.district.listEmail[i];

        if (obj == removedOption.name) {
          this.district.listEmail.splice(i, 1);
        }
      }
    },
    paginationChanged: function (page) {
      if (page == null) {
        page = 1;
      }

      this.districtsPageItems = this.districts.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    },
    validateEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.districtsPageItems = [];
      this.isSearching = true;
      var result = await this.$store
        .dispatch("districtController/GetDistricts")
        .catch((error) => {
          this.isSearching = false;
        });

      if (result) {
        this.paginationChanged(this.currentPage);
        this.isSearching = false;
      }
    },
    selectDM: async function name(dm) {
      this.isEdit = true;
      this.listEmail = [];
      await this.setDistrict(Object.assign({}, dm));

      this.district.listEmail.forEach((email) => {
        const tag = {
          name: email,
          code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
        };

        this.listEmail.push(tag);
      });
      this.dmModalShow = true;
    },
    selectDMAgents: async function (dmCode) {
      await this.$store.dispatch("showOverlay", true);
      this.dmCode = dmCode;
      var result = await this.$store
        .dispatch("districtController/GetDistrictsAgents", dmCode)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
        });

      if (result) {
        this.dmAgentsModalShow = true;
        await this.$store.dispatch("showOverlay", false);
      }
    },
    submitDistrict: async function () {
      this.$v.district.$touch();
      this.district.listEmail = this.listEmail.reduce(
        (a, o) => (o.name && a.push(o.name), a),
        []
      );

      if (!this.$v.district.$invalid) {
        await this.$store.dispatch("showOverlay", true);

        if (this.isEdit) {
          var result = await this.$store
            .dispatch("districtController/UpdateDistrict")
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
            });

          if (result) {
            this.$v.district.$reset();
            await this.searchFilter();
            this.dmModalShow = false;
            await this.$store.dispatch("showOverlay", false);
          }
        } else {
          var result = await this.$store
            .dispatch("districtController/AddDistrict")
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
            });

          if (result) {
            await this.searchFilter();
            this.$v.district.$reset();
            this.dmModalShow = false;
            await this.$store.dispatch("showOverlay", false);
          }
        }
      }
    },
    closeModal: async function () {
      await this.searchFilter();
      this.dmModalShow = !this.dmModalShow;
      this.$v.$reset();
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style></style>