<template>
  <!-- generate report modal -->
  <b-modal
    v-model="show"
    size="md"
    title="Generate All Reports"
    hide-footer
    no-close-on-backdrop
    hide-header-close
  >
    <div class="allReportFilters" v-if="!isSubmitting">
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtReportDateRange">Report Date</label>
              <date-picker
                v-model="dateRange"
                :clearable="false"
                format="MM/DD/YYYY"
                title-format="MM/DD/YYYY"
                time-title-format="MM/DD/YYYY"
                input-class="form-control"
                :input-attr="{ id: 'txtReportDateRange' }"
                :disabled="isSubmitting"
                range
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtPrincipalCode">Principal Code</label>
              <multiselect
                id="txtPrincipalCode"
                v-model="value"
                placeholder="Search Principal Code"
                label="name"
                track-by="code"
                :options="principalOptions"
                :multiple="true"
                :disabled="isSubmitting"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check form-check-flat form-check-primary">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="isOfficial"
                  :disabled="isSubmitting" />
                Is Official
                <i class="input-helper"></i
              ></label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-primary mr-2"
              :disabled="isSubmitting"
              @click="generateReports"
            >
              <i class="fas fa-spinner fa-spin mr-1" v-if="isSubmitting" />
              Generate
            </button>
            <button
              type="button"
              class="btn btn-light"
              :disabled="isSubmitting"
              @click="close"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="generateReportRunning" v-if="isSubmitting">
      <div class="row">
        <div class="col">
          <b-progress
            :value="doneReportsPercentage"
            :max="100"
            variant="info"
            height="2rem"
            show-progress
            animated
          ></b-progress>
          <p class="mt-2" v-if="!doneGenerate">
            <i class="fas fa-spinner fa-spin mr-1" />
            Generating {{ reportName }} . . .
          </p>
          <p class="mt-2" v-if="doneGenerate && !errorMessages">
            <i class="fas fa-check"></i>
            Reports has been successfully generated.
          </p>
          <p class="mt-2" v-if="doneGenerate && errorMessages">
            <i class="fas fa-exclamation-triangle"></i>
            Reports has been generated with some errors below.
          </p>
          <b-alert
            show
            variant="danger"
            v-if="errorMessages"
            v-html="errorMessages"
          ></b-alert>
        </div>
      </div>
      <div class="row mt-2 text-center">
        <div class="col">
          <b-button type="button" v-if="doneGenerate" @click="close">
            Close
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    show: {
      Boolean,
    },
  },
  data() {
    return {
      doneGenerate: false,
      errorMessages: "",
      numberOfReports: 4,
      reportName: "",
      doneReportsPercentage: 2,
      isSubmitting: false,
      isOfficial: false,
      dateRange: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(),
      ],
      value: [],
    };
  },
  computed: {
    ...mapState({
      principals: (state) => state.principalController.principals,
      stockOnHandReportFilter: (state) =>
        state.reportController.stockOnHandReportFilter,
      deliveryMonitoringReportFilter: (state) =>
        state.reportController.deliveryMonitoringReportFilter,
      invoiceListingReportFilter: (state) =>
        state.reportController.invoiceListingReportFilter,
      salesSummaryReportFilter: (state) =>
        state.reportController.salesSummaryReportFilter,
      salesReturnReportFilter: (state) =>
        state.reportController.salesReturnReportFilter,
    }),
    principalOptions() {
      return this.principals.map((mg) => ({
        ["name"]: mg.principalCode,
        ["code"]: mg.principalCode + "-001",
      }));
    },
  },
  methods: {
    close() {
      this.isSubmitting = false;
      this.isOfficial = false;
      this.dateRange = [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(),
      ];

      this.$emit("on-close", false);
    },
    async generateReports() {
      this.doneGenerate = false;
      this.isSubmitting = true;
      this.errorMessages = "";
      this.doneReportsPercentage = 2;
      this.reportName = "";

      await this.generateInvoiceListingReport();
      await this.generateSalesSummaryReport();
      await this.generateSalesReturnReport();
      await this.generateDeliveryMonitoringReport();
      //await this.generateStockOnHandReport();

      this.doneGenerate = true;
    },
    async generateStockOnHandReport() {
      this.reportName = "Stock On Hand Report";
      this.stockOnHandReportFilter.date = this.dateRange[1]; // end date
      this.stockOnHandReportFilter.isOfficial = this.isOfficial;
      this.stockOnHandReportFilter.principalCode = this.value
        .map((mg) => mg.name)
        .join("~");

      var result = await this.$store
        .dispatch("reportController/GetStockOnHandReports")
        .catch(async (error) => {
          this.errorMessages += `<b>${this.reportName} error: </b>${error.data.Message}<br/>`;
          this.doneReportsPercentage = (1 / this.numberOfReports) * 100;
        });

      if (result) {
        this.doneReportsPercentage = (1 / this.numberOfReports) * 100;
      }
    },
    async generateDeliveryMonitoringReport() {
      this.reportName = "Delivery Monitoring Report";
      this.deliveryMonitoringReportFilter.dateRange = this.dateRange;
      this.deliveryMonitoringReportFilter.isOfficial = this.isOfficial;
      this.deliveryMonitoringReportFilter.principalCode = this.value
        .map((mg) => mg.name)
        .join("~");

      var result = await this.$store
        .dispatch("reportController/GetDeliveryMonitoringReports")
        .catch(async (error) => {
          this.errorMessages += `<b>${this.reportName} error: </b>${error.data.Message}<br/>`;
          this.doneReportsPercentage = (2 / this.numberOfReports) * 100;
        });

      if (result) {
        this.doneReportsPercentage = (2 / this.numberOfReports) * 100;
      }
    },
    generateInvoiceListingReport: async function () {
      this.reportName = "Invoice Listing Report";
      this.invoiceListingReportFilter.dateRange = this.dateRange;
      this.invoiceListingReportFilter.isOfficial = this.isOfficial;
      this.invoiceListingReportFilter.principalCode = this.value
        .map((mg) => mg.name)
        .join("~");

      var result = await this.$store
        .dispatch("reportController/GenerateInvoiceListing")
        .catch(async (error) => {
          this.errorMessages += `<b>${this.reportName} error: </b>${error.data.Message}<br/>`;
          this.doneReportsPercentage = (3 / this.numberOfReports) * 100;
        });

      if (result) {
        this.doneReportsPercentage = (3 / this.numberOfReports) * 100;
      }
    },
    generateSalesSummaryReport: async function () {
      this.reportName = "Sales Summary Report";
      this.salesSummaryReportFilter.dateRange = this.dateRange;
      this.salesSummaryReportFilter.isOfficial = this.isOfficial;
      this.salesSummaryReportFilter.principalCode = this.value
        .map((mg) => mg.name)
        .join("~");

      var result = await this.$store
        .dispatch("reportController/GenerateSalesSummary")
        .catch(async (error) => {
          this.errorMessages += `<b>${this.reportName} error: </b>${error.data.Message}<br/>`;
          this.doneReportsPercentage = (4 / this.numberOfReports) * 100;
        });

      if (result) {
        this.doneReportsPercentage = (4 / this.numberOfReports) * 100;
      }
    },
    generateSalesReturnReport: async function () {
      this.reportName = "Sales Return Report";
      this.salesReturnReportFilter.dateRange = this.dateRange;
      this.salesReturnReportFilter.isOfficial = this.isOfficial;
      this.salesReturnReportFilter.principalCode = this.value
        .map((mg) => mg.name)
        .join("~");

      var result = await this.$store
        .dispatch("reportController/GenerateSalesReturn")
        .catch(async (error) => {
          this.errorMessages += `<b>${this.reportName} error: </b>${error.data.Message}<br/>`;
          this.doneReportsPercentage = (5 / this.numberOfReports) * 100;
        });

      if (result) {
        this.doneReportsPercentage = (5 / this.numberOfReports) * 100;
      }
    },
  },
};
</script>

<style scoped>
.fa-check {
  color: #00cf2f;
}
.fa-exclamation-triangle {
  color: #e5aa00;
}
</style>