import axios from "axios";
import Vue from "vue";

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        customers: [],
        customer: {
            selectedTerritoryCode: {},
            customerCode: '', // required
            customerName: '', // required
            customerAddress: '', // required
            shipToCode: '', // required
            shipToAddress: '', // required
            zipCode: '', // required
            territoryCode: '', // required
            billCollectorCode: '', // required
            billCollectorName: '', // required
            salesAgentCode: '', // required
            salesAgentName: '', // required
            dmCode: '',
            dmName: '',
            contactNumber: '',
            contactPerson: '',
            creditTermCode: '', // required
            creditTermDescription: '', // required
            customerClass: '', // required
            tin: '',
            ltoNumber: '',
            ltoExpiryDate: new Date(),
            sLicense: '',
            sLicenseExpiryDate: new Date(),
            vatStatus: '', // required
            ownerName: '',
            state: '', // required
            area: '', // required
            territory: '', // required
            createdDate: new Date(), // required
            createdTime: '', // required
            createdBy: '', // required
        },
        filters: {
            customerCode: '',
            customerName: '',
        }
    },
    mutations: {
        SET_Customers(state, data) {
            state.customers = data;
        },
        RESET_Customers(state) {
            state.customers = [];
        },
        BIND_Customer(state, customer) {
            customer.selectedTerritoryCode = {
                name: customer.territoryCode
            };
            const copy = Object.assign({}, customer);
            state.customer = copy;
        },
        RESET_Customer(state) {
            state.customer = {
                selectedTerritoryCode: {},
                customerCode: '',
                customerName: '',
                customerAddress: '',
                shipToCode: '',
                shipToAddress: '',
                zipCode: '',
                territoryCode: '',
                billCollectorCode: '',
                billCollectorName: '',
                salesAgentCode: '',
                salesAgentName: '',
                dmCode: '',
                dmName: '',
                contactNumber: '',
                contactPerson: '',
                creditTermCode: '',
                creditTermDescription: '',
                customerClass: '',
                tin: '',
                ltoNumber: '',
                ltoExpiryDate: new Date(),
                sLicense: '',
                sLicenseExpiryDate: new Date(),
                vatStatus: '',
                ownerName: '',
                state: '',
                area: '',
                territory: '',
                createdDate: new Date(),
                createdTime: '',
                createdBy: '',
            }
        },
        UPDATE_Customer(state, data) {
            var index = state.customers.findIndex((obj => obj.customerCode == data.customerCode));

            if (index >= 0) {
                Vue.set(state.customers, index, data);
            } else {
                state.customers.push(data);
            }
        },
        REMOVE_Customer(state, data) {
            var index = state.customers.findIndex((obj => obj.customerCode == data.customerCode));

            if (index >= 0) {
                state.customers.splice(index, 1);
            }
        },
    },
    getters: {
        filters(state) {
            return state.filters
        },
        customer(state) {
            return state.customer
        }
    },
    actions: {
        GetCustomers({ getters, commit, rootGetters }) {
            var filter = getters.filters;
            commit('RESET_Customers');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Customer?code=" + filter.customerCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Customers', response.data)
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        AddCustomer({ getters, commit, rootGetters }) {
            var customer = getters.customer;

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Customer", customer, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('UPDATE_Customer', response.data)
                        commit('RESET_Customer');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        EditCustomer({ getters, commit, rootGetters }) {
            var customer = getters.customer;

            return new Promise((resolve, reject) => {
                instance
                    .put("api/Customer/" + customer.customerCode, customer, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('UPDATE_Customer', response.data)
                        commit('RESET_Customer');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        DeleteCustomer({ getters, commit, rootGetters }) {
            var customer = getters.customer;

            return new Promise((resolve, reject) => {
                instance
                    .delete("api/Customer/" + customer.customerCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('REMOVE_Customer', customer)
                        commit('RESET_Customer');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        UploadCustomer({ commit, rootGetters }, file) {
            let formData = new FormData();
            formData.append('file', file);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Customer/batch", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        UploadCustomer({ commit, rootGetters }, file) {
            let formData = new FormData();
            formData.append('file', file);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/customer/batch", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        DownloadTemplate({ rootGetters }) {
            return new Promise((resolve, reject) => {
                instance
                    .get("api/customer/export/template", {
                        responseType: 'blob',
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function (response) {
                        var filename = response.headers['content-disposition'].split("; ")[1].split('=')[1].replace(/"/g, '');
                        const url = URL.createObjectURL(new Blob([response.data], {
                            type: 'application/octet-stream'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();

                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        ResetCustomer({ commit }) {
            commit('RESET_Customer');
        },
        BindCustomer({ commit }, customer) {
            commit('BIND_Customer', customer);
        }
    },
    modules: {},
};