import Vue from 'vue'
import Vuex from 'vuex'
import jwt_decode from "jwt-decode";

import userController from './userController'
import principalController from './principalController'
import customerController from './customerController'
import satController from './satController'
import reportController from './reportController'
import territoryController from './territoryController'
import districtController from './districtController'
import nationalSalesController from './nationalSalesController'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showOverlay: false,
        admin: {
            info: {
                name: '',
                email: ''
            }
        }
    },
    getters: {
        token(state) {
            var name = "token=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(";");

            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    var token = atob(c.substring(name.length, c.length));
                    var payload = jwt_decode(token);

                    state.admin.info.name = payload.unique_name;
                    state.admin.info.email = payload.email;

                    return token;
                }
            }

            return '';
        }
    },
    mutations: {
        SET_OverlayVisibility(state, isShow) {
            state.showOverlay = isShow;
        }
    },
    actions: {
        setToken({}, token) {
            var cookieValue = btoa(token);
            var now = new Date();
            var time = now.getTime();
            time += 3600 * 1000;
            now.setTime(time);

            var expires = "expires=" + now;
            document.cookie = "token=" + cookieValue + ";" + expires + ";path=/;"; // remove "secure" when not in production
        },
        deleteToken() {
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
        showOverlay({ commit }, isShow) {
            commit("SET_OverlayVisibility", isShow);
        },
    },
    modules: {
        userController,
        principalController,
        customerController,
        satController,
        reportController,
        territoryController,
        districtController,
        nationalSalesController
    }
})