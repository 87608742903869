import axios from "axios";
import Vue from "vue";

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        stateAreaTerritories: [],
        stateAreaTerritory: {
            state: '',
            area: '',
            territory: '',
            stateCode: '',
            areaCode: '',
            territoryCode: '',
            salesAgentCode: '',
            salesAgentName: '',
            billCollectorCode: '',
            billCollectorName: '',
            dMCode: '',
            dMName: '',
            nSMCode: '',
            nSMName: '',
            remarks: '',
        },
        filters: {
            territoryCode: '',
        }
    },
    mutations: {
        SET_StateAreaTerritories(state, data) {
            state.stateAreaTerritories = data;
        },
        RESET_StateAreaTerritories(state) {
            state.stateAreaTerritories = [];
        },
        BIND_StateAreaTerritory(state, stateAreaTerritory) {
            state.stateAreaTerritory = stateAreaTerritory
        },
        RESET_StateAreaTerritory(state) {
            state.stateAreaTerritory = {
                state: '',
                area: '',
                territory: '',
                stateCode: '',
                areaCode: '',
                territoryCode: '',
                salesAgentCode: '',
                salesAgentName: '',
                billCollectorCode: '',
                billCollectorName: '',
                dMCode: '',
                dMName: '',
                nSMCode: '',
                nSMName: '',
                remarks: '',
            }
        },
        UPDATE_StateAreaTerritory(state, data) {
            var index = state.stateAreaTerritories.findIndex((obj => obj.territoryCode == data.territoryCode));

            if (index >= 0) {
                Vue.set(state.stateAreaTerritories, index, data);
            } else {
                state.stateAreaTerritories.push(data);
            }
        },
        REMOVE_StateAreaTerritory(state, data) {
            var index = state.stateAreaTerritories.findIndex((obj => obj.territoryCode == data.territoryCode));

            if (index >= 0) {
                state.stateAreaTerritories.splice(index, 1);
            }
        },
    },
    getters: {
        filters(state) {
            return state.filters
        },
        stateAreaTerritory(state) {
            return state.stateAreaTerritory
        }
    },
    actions: {
        GetStateAreaTerritories({ getters, commit, rootGetters }) {
            var filter = getters.filters;
            commit('RESET_StateAreaTerritories');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/sat?territoryCode=" + filter.territoryCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_StateAreaTerritories', response.data)
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        AddStateAreaTerritory({ getters, commit, rootGetters }) {
            var stateAreaTerritory = getters.stateAreaTerritory;

            return new Promise((resolve, reject) => {
                instance
                    .post("api/sat", stateAreaTerritory, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('UPDATE_StateAreaTerritory', response.data)
                        commit('RESET_StateAreaTerritory');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        EditStateAreaTerritory({ getters, commit, rootGetters }) {
            var stateAreaTerritory = getters.stateAreaTerritory;

            return new Promise((resolve, reject) => {
                instance
                    .put("api/sat/" + stateAreaTerritory.territoryCode, stateAreaTerritory, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('UPDATE_StateAreaTerritory', response.data)
                        commit('RESET_StateAreaTerritory');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        DeleteStateAreaTerritory({ getters, commit, rootGetters }) {
            var stateAreaTerritory = getters.stateAreaTerritory;

            return new Promise((resolve, reject) => {
                instance
                    .delete("api/sat/" + stateAreaTerritory.territoryCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('REMOVE_StateAreaTerritories', stateAreaTerritory)
                        commit('RESET_StateAreaTerritories');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        UploadStateAreaTerritory({ commit, rootGetters }, file) {
            let formData = new FormData();
            formData.append('file', file);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/sat/batch", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        DownloadTemplate({ rootGetters }) {
            return new Promise((resolve, reject) => {
                instance
                    .get("api/sat/export/template", {
                        responseType: 'blob',
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function (response) {
                        var filename = response.headers['content-disposition'].split("; ")[1].split('=')[1].replace(/"/g, '');
                        const url = URL.createObjectURL(new Blob([response.data], {
                            type: 'application/octet-stream'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();

                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        ResetStateAreaTerritory({ commit }) {
            commit('RESET_StateAreaTerritory');
        },
        BindStateAreaTerritory({ commit }, stateAreaTerritory) {
            commit('BIND_StateAreaTerritory', stateAreaTerritory);
        }
    },
    modules: {},
};