<template>
  <div class="customer-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Customer Maintenance</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <label>Customer Code:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="filter.customerCode"
                        placeholder="Customer Code"
                        :disabled="isSearching"
                      />
                    </div>
                    <div class="col">
                      <p style="margin-bottom: 0.3rem">&nbsp;</p>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="searchFilter"
                        :disabled="isSearching"
                      >
                        <i class="fas fa-search"></i>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-right"
                >
                  <p style="margin-bottom: 0rem">&nbsp;</p>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm-block mt-1"
                    :disabled="isSearching || isDownloading"
                    @click="downloadTemplate"
                  >
                    <i class="fas fa-download"></i>
                    Export Customers
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm-block mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                    :disabled="isSearching"
                    @click="
                      isEdit = false;
                      customerModalShow = !customerModalShow;
                      listEmailStockOnHand = [];
                      listEmailDeliveryMonitoring = [];
                      listEmailInvoiceListing = [];
                      $store.dispatch('customerController/ResetCustomer');
                    "
                  >
                    <i class="fas fa-user-plus"></i>
                    Add Customer
                  </button>
                  <button
                    type="button"
                    :disabled="isSearching"
                    class="btn btn-primary btn-sm-block mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                    @click="
                      isEdit = false;
                      uploadCustomerModalShow = !uploadCustomerModalShow;
                      $store.dispatch('customerController/ResetCustomer');
                    "
                  >
                    <i class="fas fa-upload"></i>
                    Upload Customer
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Customer Code</th>
                    <th>Customer Name</th>
                    <th>Customer Address</th>
                    <th>Ship To Code</th>
                    <th>Ship To Address</th>
                    <th>Zip Code</th>
                    <th>Territory Code</th>
                    <th>Bill Collector Code</th>
                    <th>Bill Collector Name</th>
                    <th>Sales Agent Code</th>
                    <th>Sales Agent Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="customersPageItems.length == 0 && !isSearching">
                    <td colspan="10">
                      <div>
                        <lottie-animation
                          style="height: 500px"
                          :loop="true"
                          :animationData="
                            require('@/assets/lottie/no-data.json')
                          "
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="isSearching">
                    <td colspan="10">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(customer, i) in customersPageItems" :key="i">
                    <td>{{ customer.customerCode }}</td>
                    <td>{{ customer.customerName }}</td>
                    <td>{{ customer.customerAddress }}</td>
                    <td>{{ customer.shipToCode }}</td>
                    <td>{{ customer.shipToAddress }}</td>
                    <td>{{ customer.zipCode }}</td>
                    <td>{{ customer.territoryCode }}</td>
                    <td>{{ customer.billCollectorCode }}</td>
                    <td>{{ customer.billCollectorName }}</td>
                    <td>{{ customer.salesAgentCode }}</td>
                    <td>{{ customer.salesAgentName }}</td>
                    <td>
                      <a
                        href="#"
                        @click="
                          editCustomer(customer);
                          isEdit = true;
                        "
                      >
                        Edit
                      </a>
                      |
                      <a href="#" @click="deleteCustomer(customer)">Delete</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination
              v-if="customersPageItems.length != 0"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="pageSize"
              aria-controls="my-table"
              @change="paginationChanged"
              style="float: right; margin-top: 20px"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="customerModalShow"
      size="xl"
      title="Customer Details"
      hide-footer
      no-close-on-backdrop
    >
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCustomerCode" ref="modalCustomerDetails">
                Customer Code
              </label>
              <input
                type="text"
                class="form-control"
                id="txtCustomerCode"
                placeholder="Customer Code"
                :class="{
                  'is-invalid': $v.customer.customerCode.$error,
                }"
                v-model="$v.customer.customerCode.$model"
                :disabled="isEdit"
              />
              <div class="form-error" v-if="$v.customer.customerCode.$error">
                Customer Code is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCustomerName">Customer Name</label>
              <input
                type="text"
                class="form-control"
                id="txtCustomerName"
                placeholder="Customer Name"
                :class="{
                  'is-invalid': $v.customer.customerName.$error,
                }"
                v-model="$v.customer.customerName.$model"
              />
              <div class="form-error" v-if="$v.customer.customerName.$error">
                Customer Name is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCustomerAddress">Customer Address</label>
              <input
                type="text"
                class="form-control"
                id="txtCustomerAddress"
                placeholder="Customer Address"
                :class="{
                  'is-invalid': $v.customer.customerAddress.$error,
                }"
                v-model="$v.customer.customerAddress.$model"
              />
              <div class="form-error" v-if="$v.customer.customerAddress.$error">
                CustomerAddress is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtShipToCode">Ship To Code</label>
              <input
                type="text"
                class="form-control"
                id="txtShipToCode"
                placeholder="Ship To Code"
                :class="{
                  'is-invalid': $v.customer.shipToCode.$error,
                }"
                v-model="$v.customer.shipToCode.$model"
              />
              <div class="form-error" v-if="$v.customer.shipToCode.$error">
                Ship To Code is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtShipToAddress">Ship To Address</label>
              <input
                type="text"
                class="form-control"
                id="txtShipToAddress"
                placeholder="Ship To Address"
                :class="{
                  'is-invalid': $v.customer.shipToAddress.$error,
                }"
                v-model="$v.customer.shipToAddress.$model"
              />
              <div class="form-error" v-if="$v.customer.shipToAddress.$error">
                Ship To Address is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtZipCode">Zip Code</label>
              <input
                type="text"
                class="form-control"
                id="txtZipCode"
                placeholder="Zip Code"
                :class="{
                  'is-invalid': $v.customer.zipCode.$error,
                }"
                v-model="$v.customer.zipCode.$model"
              />
              <div class="form-error" v-if="$v.customer.zipCode.$error">
                Zip Code is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtBillCollectorCode">Bill Collector Code</label>
              <input
                type="text"
                class="form-control"
                id="txtBillCollectorCode"
                placeholder="Bill Collector Code"
                v-model="customer.billCollectorCode"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtBillCollectorName">Bill Collector Name</label>
              <input
                type="text"
                class="form-control"
                id="txtBillCollectorName"
                placeholder="Bill Collector Name"
                v-model="customer.billCollectorName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtSalesAgentCode">Sales Agent Code</label>
              <input
                type="text"
                class="form-control"
                id="txtSalesAgentCode"
                placeholder="Sales Agent Code"
                v-model="customer.salesAgentCode"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtSalesAgentName">Sales Agent Name</label>
              <input
                type="text"
                class="form-control"
                id="txtSalesAgentName"
                placeholder="Sales Agent Name"
                v-model="customer.salesAgentName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtDMCode">DM Code</label>
              <input
                type="text"
                class="form-control"
                id="txtDMCode"
                placeholder="DM Code"
                v-model="customer.dmCode"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtDMName">DM Name</label>
              <input
                type="text"
                class="form-control"
                id="txtDMName"
                placeholder="DM Name"
                v-model="customer.dmName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtTerritoryCode">Territory Code</label>
              <multiselect
                v-model="customer.selectedTerritoryCode"
                label="name"
                track-by="name"
                :options="stateAreaTerritoriesOptions"
                @select="populateSAT"
                :class="{
                  'is-invalid': $v.customer.territoryCode.$error,
                }"
              ></multiselect>
              <div class="form-error" v-if="$v.customer.territoryCode.$error">
                Territory Code is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtTerritory">Territory</label>
              <input
                type="text"
                class="form-control"
                id="txtTerritory"
                placeholder="Territory"
                v-model="customer.territory"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtState">State</label>
              <input
                type="text"
                class="form-control"
                id="txtState"
                placeholder="State"
                v-model="customer.state"
                disabled
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtArea">Area</label>
              <input
                type="text"
                class="form-control"
                id="txtArea"
                placeholder="Area"
                v-model="customer.area"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtContactNumber">Contact Number</label>
              <input
                type="text"
                class="form-control"
                id="txtContactNumber"
                placeholder="Contact Number"
                v-model="customer.contactNumber"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtContactPerson">Contact Person</label>
              <input
                type="text"
                class="form-control"
                id="txtContactPerson"
                placeholder="Contact Person"
                v-model="customer.contactPerson"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCreditTermCode">Credit Term Code</label>
              <input
                type="text"
                class="form-control"
                id="txtCreditTermCode"
                placeholder="Credit Term Code"
                :class="{
                  'is-invalid': $v.customer.creditTermCode.$error,
                }"
                v-model="$v.customer.creditTermCode.$model"
              />
              <div class="form-error" v-if="$v.customer.creditTermCode.$error">
                Credit Term Code is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCreditTermDescription"
                >Credit Term Description</label
              >
              <input
                type="text"
                class="form-control"
                id="txtCreditTermDescription"
                placeholder="Credit Term Description"
                :class="{
                  'is-invalid': $v.customer.creditTermDescription.$error,
                }"
                v-model="$v.customer.creditTermDescription.$model"
              />
              <div
                class="form-error"
                v-if="$v.customer.creditTermDescription.$error"
              >
                Credit Term Description is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCustomerClass">Customer Class</label>
              <input
                type="text"
                class="form-control"
                id="txtCustomerClass"
                placeholder="Customer Class"
                :class="{
                  'is-invalid': $v.customer.customerClass.$error,
                }"
                v-model="$v.customer.customerClass.$model"
              />
              <div class="form-error" v-if="$v.customer.customerClass.$error">
                Customer Class is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtTIN">TIN</label>
              <input
                type="text"
                class="form-control"
                id="txtTIN"
                placeholder="TIN"
                v-model="customer.tin"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtLTONumber">LTO Number</label>
              <input
                type="text"
                class="form-control"
                id="txtLTONumber"
                placeholder="LTO Number"
                v-model="customer.ltoNumber"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtLTOExpiryDate">LTO Expiry Date</label>
              <date-picker
                v-model="customer.ltoExpiryDate"
                :clearable="false"
                input-class="form-control"
                :input-attr="{ id: 'txtLTOExpiryDate' }"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtSLicense">S License</label>
              <input
                type="text"
                class="form-control"
                id="txtSLicense"
                placeholder="S License"
                v-model="customer.sLicense"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtSLicenseExpiryDate">S License Expiry Date</label>
              <date-picker
                v-model="customer.sLicenseExpiryDate"
                :clearable="false"
                input-class="form-control"
                :input-attr="{ id: 'txtSLicenseExpiryDate' }"
              ></date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtVATStatus">VAT Status</label>
              <input
                type="text"
                class="form-control"
                id="txtVATStatus"
                placeholder="VAT Status"
                :class="{
                  'is-invalid': $v.customer.vatStatus.$error,
                }"
                v-model="$v.customer.vatStatus.$model"
              />
              <div class="form-error" v-if="$v.customer.vatStatus.$error">
                VAT Status is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtOwnerName">Owner Name</label>
              <input
                type="text"
                class="form-control"
                id="txtOwnerName"
                placeholder="Owner Name"
                v-model="customer.ownerName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCreatedDate">Created Date</label>
              <date-picker
                :clearable="false"
                input-class="form-control"
                :input-attr="{ id: 'txtCreatedDate' }"
                :class="{
                  'is-invalid': $v.customer.createdDate.$error,
                }"
                v-model="$v.customer.createdDate.$model"
              ></date-picker>
              <div class="form-error" v-if="$v.customer.createdDate.$error">
                Created Date is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCreatedTime">Created Time</label>
              <input
                type="text"
                class="form-control"
                id="txtCreatedTime"
                placeholder="Created Time"
                :class="{
                  'is-invalid': $v.customer.createdTime.$error,
                }"
                v-model="$v.customer.createdTime.$model"
              />
              <div class="form-error" v-if="$v.customer.createdTime.$error">
                Created Time is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtCreatedBy">Created By</label>
              <input
                type="text"
                class="form-control"
                id="txtCreatedBy"
                placeholder="Created By"
                :class="{
                  'is-invalid': $v.customer.createdBy.$error,
                }"
                v-model="$v.customer.createdBy.$model"
              />
              <div class="form-error" v-if="$v.customer.createdBy.$error">
                Created By is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-primary mr-2"
              @click="submitCustomer"
            >
              Submit
            </button>
            <button
              type="button"
              class="btn btn-outline-light"
              @click="
                customerModalShow = !customerModalShow;
                $v.$reset();
              "
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- upload modal -->
    <b-modal
      v-model="uploadCustomerModalShow"
      size="md"
      title="Upload Customer"
      hide-footer
      no-close-on-backdrop
    >
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Customer File</label>
              <input
                class="form-control"
                type="file"
                v-on:change="handleFileUpload($event)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-primary mr-2"
              @click="uploadFile"
            >
              Upload
            </button>
            <button
              type="button"
              class="btn btn-outline-light"
              @click="uploadCustomerModalShow = !uploadCustomerModalShow"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      file: null,
      uploadCustomerModalShow: false,
      customersPageItems: [],
      listEmailInvoiceListing: [],
      listEmailStockOnHand: [],
      listEmailDeliveryMonitoring: [],
      options: [],
      customerModalShow: false,
      pageSize: 50,
      currentPage: 1,
      isSearching: false,
      isDownloading: false,
      isEdit: false,
    };
  },

  validations: {
    file: {
      required,
    },
    customer: {
      customerCode: {
        required,
      },
      customerName: {
        required,
      },
      customerAddress: {
        required,
      },
      shipToCode: {
        required,
      },
      shipToAddress: {
        required,
      },
      zipCode: {
        required,
      },
      territoryCode: {
        required,
      },
      billCollectorCode: {
        required,
      },
      billCollectorName: {
        required,
      },
      salesAgentCode: {
        required,
      },
      salesAgentName: {
        required,
      },
      creditTermCode: {
        required,
      },
      creditTermDescription: {
        required,
      },
      customerClass: {
        required,
      },
      vatStatus: {
        required,
      },
      state: {
        required,
      },
      area: {
        required,
      },
      territory: {
        required,
      },
      createdDate: {
        required,
      },
      createdTime: {
        required,
      },
      createdBy: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      stateAreaTerritories: (state) => state.satController.stateAreaTerritories,
      customers: (state) => state.customerController.customers,
      customer: (state) => state.customerController.customer,
      filter: (state) => state.customerController.filters,
    }),
    rows() {
      return this.customers.length;
    },
    stateAreaTerritoriesOptions() {
      return this.stateAreaTerritories.map(function (a) {
        return {
          name: a.territoryCode,
        };
      });
    },
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      await this.$store.dispatch("showOverlay", true);
      var result = await this.$store
        .dispatch("customerController/UploadCustomer", this.file)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data,
            type: "error",
          });
        });

      if (result) {
        await this.searchFilter();
        await this.$store.dispatch("showOverlay", false);
        this.uploadCustomerModalShow = false;
        this.$toast.open({
          message: "Customer batch file has been successfully uploaded.",
          type: "success",
        });
      }
    },
    paginationChanged: function (page) {
      if (page == null) {
        page = 1;
      }

      this.customersPageItems = this.customers.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    },
    validateEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    downloadTemplate: async function () {
      this.isDownloading = true;
      var result = await this.$store.dispatch(
        "customerController/DownloadTemplate"
      );

      if (result) {
        this.isDownloading = false;
      }
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.customersPageItems = [];
      this.isSearching = true;
      var result = await this.$store
        .dispatch("customerController/GetCustomers")
        .catch((error) => {
          this.isSearching = false;
        });

      if (result) {
        this.paginationChanged(this.currentPage);
        this.isSearching = false;
      }
    },
    populateSAT: function (selected, id) {
      var sat = this.stateAreaTerritories.filter(
        (m) => m.territoryCode == selected.name
      )[0];

      this.customer.state = sat.state;
      this.customer.area = sat.area;
      this.customer.territory = sat.territory;
      this.customer.territoryCode = sat.territoryCode;
    },
    submitCustomer: async function () {
      this.$v.customer.$touch();

      if (!this.$v.customer.$invalid) {
        if (this.isEdit) {
          await this.$store.dispatch("showOverlay", true);
          // edit existing record
          var result = await this.$store
            .dispatch("customerController/EditCustomer")
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });

          if (result) {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: "Customer has been successfully edited.",
              type: "success",
            });

            this.paginationChanged(this.currentPage);

            this.customerModalShow = false;
            this.listEmailInvoiceListing = [];
            this.listEmailStockOnHand = [];
            this.listEmailDeliveryMonitoring = [];

            this.$v.customer.$reset();
          }
        } else {
          await this.$store.dispatch("showOverlay", true);
          // insert new record
          var result = await this.$store
            .dispatch("customerController/AddCustomer")
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });

          if (result) {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: "Customer has been successfully added.",
              type: "success",
            });

            this.paginationChanged(this.currentPage);

            this.customerModalShow = false;
            this.listEmailInvoiceListing = [];
            this.listEmailStockOnHand = [];
            this.listEmailDeliveryMonitoring = [];

            this.$v.customer.$reset();
          }
        }
      }
    },
    editCustomer: function (customer) {
      this.$store.dispatch("customerController/BindCustomer", customer);
      this.customerModalShow = !this.customerModalShow;
    },
    deleteCustomer: function (customer) {
      var self = this;
      self.$store.dispatch("customerController/BindCustomer", customer);

      self.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete " + customer.customerCode + "?",
          {
            title: "Delete Customer",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            var result = await self.$store.dispatch(
              "customerController/DeleteCustomer",
              customer
            );

            if (result) {
              self.$toast.open({
                message:
                  "Customer " +
                  customer.customerCode +
                  " has been successfully deleted.",
                type: "success",
              });

              self.paginationChanged(self.currentPage);
            }
          }
        })
        .catch((err) => {});
    },
  },
  async mounted() {
    await this.searchFilter();
    await this.$store
      .dispatch("satController/GetStateAreaTerritories")
      .catch((error) => {});
  },
};
</script>

<style>
</style>