import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// pages
import LoginPage from '../pages/login'
import Error404Page from '../pages/Errors/404'

// layouts
import AdminLayout from '../layouts/admin'

// views
import DashboardView from '../views/dashboard'
import PrincipalView from '../views/principals'
import CustomerView from '../views/customer'
import SATView from '../views/sat'
import TerrritoryView from '../views/terrritory'
import UserManagementView from '../views/userManagement'
import DistrictView from '../views/district'
import NationalSalesView from '../views/nationalsales'
import ReportsView from '../views/reports'

// reports child
import BinCardsChild from '../views/reports/childs/bincards'
import SalesReportChild from '../views/reports/childs/sales'
import DeliveryMonitoringChild from '../views/reports/childs/deliveryMonitoring'
import BillCollectorChild from '../views/reports/childs/billCollector'
import PDCCollectionChild from '../views/reports/childs/pdcCollection'
import ReportLogs from '../views/reports/childs/logs'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/',
        name: 'Admin',
        component: AdminLayout,
        children: [{
                path: 'dashboard',
                name: 'Dashboard',
                component: DashboardView,
            },
            {
                path: 'user',
                name: 'User',
                component: UserManagementView,
            },
            {
                path: 'principal',
                name: 'Principal',
                component: PrincipalView,
            },
            {
                path: 'sat',
                name: 'SAT',
                component: SATView,
            },
            {
                path: 'customer',
                name: 'Customer',
                component: CustomerView,
            },
            {
                path: 'district',
                name: 'District',
                component: DistrictView,
            },
            {
                path: 'nationalsales',
                name: 'National Sales',
                component: NationalSalesView,
            },
            {
                path: 'territory',
                name: 'Territory',
                component: TerrritoryView,
            },
            {
                path: 'reports',
                name: 'Reports',
                component: ReportsView,
                children: [{
                        path: 'bincards',
                        name: 'Bin Card',
                        component: BinCardsChild,
                    },
                    {
                        path: 'sales',
                        name: 'Sales',
                        component: SalesReportChild,
                    },
                    {
                        path: 'deliverymonitoring',
                        name: 'Delivery Monitoring',
                        component: DeliveryMonitoringChild,
                    },
                    {
                        path: 'billcollector',
                        name: 'Bill Collector',
                        component: BillCollectorChild,
                    },
                    {
                        path: 'pdccollection',
                        name: 'PDC Collection',
                        component: PDCCollectionChild,
                    },
                    {
                        path: 'logs',
                        name: 'Report Logs',
                        component: ReportLogs,
                    },
                ]
            }
        ]
    },
    {
        path: '*',
        name: 'Error',
        component: Error404Page
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async(to, from, next) => {
    var token = await store.getters.token;

    if (to.path === '/') {
        if (token != '') {
            next("/dashboard");
        } else {
            next();
        }
    } else {
        if (token != '') {
            next();
        } else {
            next("/");
        }
    }
});

export default router