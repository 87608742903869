import axios from "axios";
import Vue from "vue";

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        territories: [],
        lstAgentAccess: [],
        agentAccess: {
            agentUserAccessID: 0,
            agentCode: '',
            name: '',
            email: '',
            reportAccess: '',
        },
        territory: {
            selectedDistrict: {},
            dmCode: "",
            dmName: "",
            agentCode: '',
            territoryCode: "",
            territoryName: "",
            agentName: "",
            district: "",
            emailList: [],
            aliases: []
        },
        filters: {
            agentCode: '',
            territoryName: '',
        }
    },
    mutations: {
        SET_Territories(state, data) {
            state.territories = data;
        },
        RESET_Territories(state) {
            state.territories = [];
        },
        BIND_Territory(state, data) {
            data.selectedDistrict = {
                name: data.dmCode
            };
            const copy = Object.assign({}, data);
            state.territory = copy;
        },
        RESET_Territory(state) {
            state.territory = {
                selectedDistrict: {},
                dmCode: "",
                dmName: "",
                agentCode: '',
                territoryCode: "",
                territoryName: "",
                agentName: "",
                district: "",
                emailList: [],
                aliases: []
            }
        },
        UPDATE_Territory(state, data) {
            var index = state.territories.findIndex((obj => obj.agentCode == data.agentCode));

            if (index >= 0) {
                Vue.set(state.territories, index, data);
            } else {
                state.territories.push(data);
            }
        },
        REMOVE_Territory(state, data) {
            var index = state.territories.findIndex((obj => obj.agentCode == data.agentCode));

            if (index >= 0) {
                state.territories.splice(index, 1);
            }
        },
        SET_LstAgentAccess(state, data) {
            state.lstAgentAccess = data
        },
        RESET_LstAgentAccess(state) {
            state.lstAgentAccess = []
        },
        RESET_AgentAccess(state) {
            state.agentAccess = {
                agentUserAccessID: 0,
                agentCode: '',
                name: '',
                email: '',
                reportAccess: '',
            }
        },
        UPDATE_AgentAccess(state, data) {
            var index = state.lstAgentAccess.findIndex((obj => obj.agentUserAccessID == data.agentUserAccessID));

            if (index >= 0) {
                Vue.set(state.lstAgentAccess, index, data);
            } else {
                state.lstAgentAccess.push(data);
            }
        },
        REMOVE_AgentAccess(state, id) {
            var index = state.lstAgentAccess.findIndex((obj => obj.agentUserAccessID == id));

            if (index >= 0) {
                state.lstAgentAccess.splice(index, 1);
            }
        },
    },
    getters: {
        filters(state) {
            return state.filters
        },
        territory(state) {
            return state.territory
        },
        agentAccess(state) {
            return state.agentAccess
        }
    },
    actions: {
        GetTerritories({ getters, commit, rootGetters }) {
            var filter = getters.filters;
            commit('RESET_Territories');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/agent/territorylisting?code=" + encodeURIComponent(filter.agentCode), {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Territories', response.data)
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        AddTerritory({ getters, commit, rootGetters }) {
            var territory = getters.territory;

            return new Promise((resolve, reject) => {
                instance
                    .post("api/agent/territorylisting", territory, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('UPDATE_Territory', response.data)
                        commit('RESET_Territory');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        EditTerritory({ getters, commit, rootGetters }) {
            var territory = getters.territory;
            console.log(territory);
            return new Promise((resolve, reject) => {
                instance
                    .put("api/agent/territorylisting/" + encodeURIComponent(territory.territoryCode), territory, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('UPDATE_Territory', response.data)
                        commit('RESET_Territory');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        DeleteTerritory({ getters, commit, rootGetters }) {
            var territory = getters.territory;

            return new Promise((resolve, reject) => {
                instance
                    .delete("api/agent/territorylisting/" + encodeURIComponent(territory.agentCode), {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('REMOVE_Territory', territory)
                        commit('RESET_Territory');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        GetAgentAccess({ commit, rootGetters }, agentCode) {
            commit('RESET_LstAgentAccess');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Agent/access?agentcode=" + agentCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_LstAgentAccess', response.data)
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        ResetAgentAccess({ commit, rootGetters }, email) {
            return new Promise((resolve, reject) => {
                instance
                    .put("api/Agent/access/reset/password?email=" + email, '', {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('RESET_AgentAccess');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        DeleteAgentAccess({ commit, rootGetters }, id) {
            return new Promise((resolve, reject) => {
                instance
                    .delete("api/Agent/access/" + id, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('REMOVE_AgentAccess',  id)
                        commit('RESET_AgentAccess');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        AddAgentAccess({ getters, commit, rootGetters }) {
            var agentAccess = getters.agentAccess;

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Agent/access", agentAccess, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('UPDATE_AgentAccess', response.data)
                        commit('RESET_AgentAccess');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        EditAgentAccess({ getters, commit, rootGetters }) {
            var agentAccess = getters.agentAccess;

            return new Promise((resolve, reject) => {
                instance
                    .put("api/Agent/access/" + agentAccess.agentUserAccessID, agentAccess, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('UPDATE_AgentAccess', response.data)
                        commit('RESET_AgentAccess');
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        ResetTerritory({ commit }) {
            commit('RESET_Territory');
        },
        BindTerritory({ commit }, territory) {
            commit('BIND_Territory', territory);
        }
    },
    modules: {},
};