var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-container"},[_c('nav',{staticClass:"sidebar sidebar-offcanvas",attrs:{"id":"sidebar"}},[_c('ul',{staticClass:"nav"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('li',{staticClass:"nav-item"},[_vm._m(7),_c('div',{staticClass:"collapse",attrs:{"id":"reports"}},[_c('ul',{staticClass:"nav flex-column sub-menu"},[_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('li',{staticClass:"nav-item",on:{"click":function($event){_vm.showGenerateAllReport = true}}},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v(" Generate All Reports ")])]),_vm._m(13)])])])])]),_c('generate-all-report-modal',{attrs:{"show":_vm.showGenerateAllReport},on:{"on-close":_vm.onClose}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/dashboard"}},[_c('i',{staticClass:"ti-home menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Dashboard")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/principal"}},[_c('i',{staticClass:"icon-grid menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Principal Maintenance")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/customer"}},[_c('i',{staticClass:"icon-grid menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Customer Maintenance")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/sat"}},[_c('i',{staticClass:"icon-grid menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("SAT Maintenance")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/district"}},[_c('i',{staticClass:"icon-grid menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("DM Maintenance")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/nationalsales"}},[_c('i',{staticClass:"icon-grid menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("NSM Maintenance")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/territory"}},[_c('i',{staticClass:"icon-grid menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Agent Maintenance")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link",attrs:{"data-toggle":"collapse","href":"#reports","aria-expanded":"false","aria-controls":"reports"}},[_c('i',{staticClass:"ti-download menu-icon"}),_c('span',{staticClass:"menu-title"},[_vm._v("Reports")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/reports/bincards"}},[_vm._v(" BIN Card for Principal ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/reports/sales"}},[_vm._v(" Sales Transaction ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/reports/deliverymonitoring"}},[_vm._v(" Delivery Monitoring ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/reports/billcollector"}},[_vm._v(" Detailed Aging ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/reports/pdccollection"}},[_vm._v(" PDC Collection ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/reports/logs"}},[_vm._v(" Report Logs ")])])
}]

export { render, staticRenderFns }