<template>
  <div class="email-logs-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Report Logs</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <label for="txtReportDateRange">
                        Report Date Range
                      </label>
                      <date-picker
                        :clearable="false"
                        format="MM/DD/YYYY"
                        title-format="MM/DD/YYYY"
                        time-title-format="MM/DD/YYYY"
                        input-class="form-control"
                        :input-attr="{ id: 'txtReportDateRange' }"
                        v-model="logFilter.dateRange"
                        range
                      ></date-picker>
                    </div>
                    <div class="col">
                      <label>Status</label>
                      <select class="form-control" v-model="logFilter.status">
                        <option value="All">All</option>
                        <option value="SUCCESS">Success</option>
                        <option value="FAILED">Failed</option>
                      </select>
                    </div>
                    <div class="col">
                      <p style="margin-bottom: 0.3rem">&nbsp;</p>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="searchFilter"
                      >
                        <i class="fas fa-search"></i>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover no-bg">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Particulars</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="isSearching">
                    <td colspan="2">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="pageItems.length == 0 && !isSearching">
                    <td colspan="2">
                      <div>
                        <lottie-animation
                          style="height: 500px"
                          :loop="true"
                          :animationData="
                            require('@/assets/lottie/no-data.json')
                          "
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(userLog, i) in pageItems" :key="i">
                    <td>
                      {{
                        new Intl.DateTimeFormat("en-GB", {
                          dateStyle: "medium",
                          timeStyle: "medium",
                        }).format(new Date(userLog.transactionDate))
                      }}
                    </td>
                    <td>{{ userLog.particulars }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination
              v-if="pageItems.length != 0"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="pageSize"
              aria-controls="my-table"
              @change="paginationChanged"
              style="float: right; margin-top: 20px"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      pageItems: [],
      currentPage: 1,
      pageSize: 50,
    };
  },
  computed: {
    ...mapState({
      logFilter: (state) => state.userController.logFilter,
      isSearching: (state) => state.userController.isSearching,
      userLogs: (state) => state.userController.userLogs,
    }),
    rows() {
      return this.userLogs.length;
    },
  },
  methods: {
    ...mapActions({
      getUserLogs: "userController/GetUserLogs",
    }),
    paginationChanged: function (page) {
      if (page == null) {
        page = 1;
      }

      this.pageItems = this.userLogs.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    },
    searchFilter: async function () {
      console.log("ss");
      this.currentPage = 1;
      this.pageItems = [];
      var result = await this.getUserLogs();

      if (result) {
        this.paginationChanged(this.currentPage);
      }
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style scoped>
</style>