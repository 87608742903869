import axios from "axios";
import Vue from "vue";

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        deliveryMonitoringReports: [],
        billCollectorReports: [],
        PDCCollectionReports: [],
        binCardReports: [],
        salesReports: [],
        stockOnHandReports: [],
        deliveryMonitoringReportFilter: {
            principalCode: '',
            isOfficial: false,
            dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
        },
        billCollectorReportFilter: {
            principalCode: '',
            isOfficial: false,
            date: new Date(),
        },
        PDCCollectionReportFilter: {
            principalCode: '',
            isOfficial: false,
            dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
        },
        stockOnHandReportFilter: {
            principalCode: '',
            isOfficial: false,
            date: new Date(),
            // dateRange: [new Date(), new Date()],
        },
        invoiceListingReportFilter: {
            principalCode: '',
            isOfficial: false,
            dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
        },
        salesSummaryReportFilter: {
            principalCode: '',
            isOfficial: false,
            dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
        },
        salesReturnReportFilter: {
            principalCode: '',
            isOfficial: false,
            dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
        }
    },
    getters: {
        salesSummaryReportFilter(state) {
            let yearFrom = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.salesSummaryReportFilter.dateRange[0]);
            let monthFrom = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.salesSummaryReportFilter.dateRange[0]);
            let dayFrom = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.salesSummaryReportFilter.dateRange[0]);
            var dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

            let yearTo = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.salesSummaryReportFilter.dateRange[1]);
            let monthTo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.salesSummaryReportFilter.dateRange[1]);
            let dayTo = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.salesSummaryReportFilter.dateRange[1]);
            var dateTo = `${yearTo}-${monthTo}-${dayTo}`;

            return {
                principalCode: state.salesSummaryReportFilter.principalCode,
                isOfficial: state.salesSummaryReportFilter.isOfficial,
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        },
        salesReturnReportFilter(state) {
            let yearFrom = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.salesReturnReportFilter.dateRange[0]);
            let monthFrom = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.salesReturnReportFilter.dateRange[0]);
            let dayFrom = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.salesReturnReportFilter.dateRange[0]);
            var dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

            let yearTo = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.salesReturnReportFilter.dateRange[1]);
            let monthTo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.salesReturnReportFilter.dateRange[1]);
            let dayTo = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.salesReturnReportFilter.dateRange[1]);
            var dateTo = `${yearTo}-${monthTo}-${dayTo}`;

            return {
                principalCode: state.salesReturnReportFilter.principalCode,
                isOfficial: state.salesReturnReportFilter.isOfficial,
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        },
        deliveryMonitoringReportFilter(state) {
            let yearFrom = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.deliveryMonitoringReportFilter.dateRange[0]);
            let monthFrom = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.deliveryMonitoringReportFilter.dateRange[0]);
            let dayFrom = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.deliveryMonitoringReportFilter.dateRange[0]);
            var dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

            let yearTo = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.deliveryMonitoringReportFilter.dateRange[1]);
            let monthTo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.deliveryMonitoringReportFilter.dateRange[1]);
            let dayTo = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.deliveryMonitoringReportFilter.dateRange[1]);
            var dateTo = `${yearTo}-${monthTo}-${dayTo}`;

            return {
                principalCode: state.deliveryMonitoringReportFilter.principalCode,
                isOfficial: state.deliveryMonitoringReportFilter.isOfficial,
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        },
        billCollectorReportFilter(state) {
            let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.billCollectorReportFilter.date);
            let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.billCollectorReportFilter.date);
            let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.billCollectorReportFilter.date);
            var date = `${year}-${month}-${day}`;
            return {
                principalCode: state.billCollectorReportFilter.principalCode,
                isOfficial: state.billCollectorReportFilter.isOfficial,
                date: date
            }
        },
        PDCCollectionReportFilter(state) {
            let yearFrom = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.PDCCollectionReportFilter.dateRange[0]);
            let monthFrom = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.PDCCollectionReportFilter.dateRange[0]);
            let dayFrom = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.PDCCollectionReportFilter.dateRange[0]);
            var dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

            let yearTo = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.PDCCollectionReportFilter.dateRange[1]);
            let monthTo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.PDCCollectionReportFilter.dateRange[1]);
            let dayTo = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.PDCCollectionReportFilter.dateRange[1]);
            var dateTo = `${yearTo}-${monthTo}-${dayTo}`;

            return {
                principalCode: state.PDCCollectionReportFilter.principalCode,
                isOfficial: state.PDCCollectionReportFilter.isOfficial,
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        },
        stockOnHandReportFilter(state) {
            // let yearFrom = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.stockOnHandReportFilter.dateRange[0]);
            // let monthFrom = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.stockOnHandReportFilter.dateRange[0]);
            // let dayFrom = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.stockOnHandReportFilter.dateRange[0]);
            // var dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

            // let yearTo = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.stockOnHandReportFilter.dateRange[1]);
            // let monthTo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.stockOnHandReportFilter.dateRange[1]);
            // let dayTo = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.stockOnHandReportFilter.dateRange[1]);
            // var dateTo = `${yearTo}-${monthTo}-${dayTo}`;

            let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.stockOnHandReportFilter.date);
            let month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.stockOnHandReportFilter.date);
            let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.stockOnHandReportFilter.date);
            var date = `${year}-${month}-${day}`;
            return {
                principalCode: state.stockOnHandReportFilter.principalCode,
                isOfficial: state.stockOnHandReportFilter.isOfficial,
                //dateFrom: dateFrom,
                dateTo: date
            }
        },
        invoiceListingReportFilter(state) {
            let yearFrom = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.invoiceListingReportFilter.dateRange[0]);
            let monthFrom = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.invoiceListingReportFilter.dateRange[0]);
            let dayFrom = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.invoiceListingReportFilter.dateRange[0]);
            var dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

            let yearTo = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.invoiceListingReportFilter.dateRange[1]);
            let monthTo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.invoiceListingReportFilter.dateRange[1]);
            let dayTo = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.invoiceListingReportFilter.dateRange[1]);
            var dateTo = `${yearTo}-${monthTo}-${dayTo}`;

            return {
                principalCode: state.invoiceListingReportFilter.principalCode,
                isOfficial: state.invoiceListingReportFilter.isOfficial,
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        },
    },
    mutations: {
        SET_DeliveryMonitoring(state, data) {
            state.deliveryMonitoringReports = data;
        },
        SET_BillCollector(state, data) {
            state.billCollectorReports = data;
        },
        SET_PDCCollection(state, data) {
            state.PDCCollectionReports = data;
        },
        SET_BinCard(state, data) {
            state.binCardReports = data;
        },
        SET_SalesReport(state, data) {
            state.salesReports = data;
        },
        SET_StockOnHand(state, data) {
            state.stockOnHandReports = data;
        }
    },
    actions: {
        GetBillCollectorReports({ getters, rootGetters }) {
            var filter = getters.billCollectorReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/billCollector?isofficial=" + filter.isOfficial + "&principalCode=" + filter.principalCode + "&date=" + filter.date, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetBillCollectorReportsAgent({ getters, rootGetters }) {
            var filter = getters.billCollectorReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/billCollector/agent?isofficial=" + filter.isOfficial + "&agentCode=" + filter.principalCode + "&date=" + filter.date, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetBillCollectorReportsDM({ getters, rootGetters }) {
            var filter = getters.billCollectorReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/billCollector/dm?isofficial=" + filter.isOfficial + "&dmCode=" + filter.principalCode + "&date=" + filter.date, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetBillCollectorReportsNSM({ getters, rootGetters }) {
            var filter = getters.billCollectorReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/billCollector/nsm?isofficial=" + filter.isOfficial + "&nsmCode=" + filter.principalCode + "&date=" + filter.date, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        UploadBillCollector({ rootGetters }, payload) {
            let formData = new FormData();
            formData.append('file', payload);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Upload/billCollector", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetPDCCollectionReports({ getters, rootGetters }) {
            var filter = getters.PDCCollectionReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/PDCCollection?isofficial=" + filter.isOfficial + "&agentCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetPDCCollectionDMReports({ getters, rootGetters }) {
            var filter = getters.PDCCollectionReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/PDCCollection/dm?isofficial=" + filter.isOfficial + "&dmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetPDCCollectionNSMReports({ getters, rootGetters }) {
            var filter = getters.PDCCollectionReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/PDCCollection/nsm?isofficial=" + filter.isOfficial + "&nsmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        UploadPDCCollection({ rootGetters }, payload) {
            let formData = new FormData();
            formData.append('file', payload);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Upload/PDCCollection", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetDeliveryMonitoringReports({ getters, rootGetters }) {
            var filter = getters.deliveryMonitoringReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/deliverymonitoring?isofficial=" + filter.isOfficial + "&principalcode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetAgentDeliveryMonitoringReports({ getters, rootGetters }) {
            var filter = getters.deliveryMonitoringReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/deliverymonitoring/agent?isofficial=" + filter.isOfficial + "&agentcode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetDMDeliveryMonitoringReports({ getters, rootGetters }) {
            var filter = getters.deliveryMonitoringReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/deliverymonitoring/dm?isofficial=" + filter.isOfficial + "&dmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetNSMDeliveryMonitoringReports({ getters, rootGetters }) {
            var filter = getters.deliveryMonitoringReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/deliverymonitoring/nsm?isofficial=" + filter.isOfficial + "&nsmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        UploadDeliveryMonitoring({ rootGetters }, payload) {
            let formData = new FormData();
            formData.append('file', payload);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Upload/deliverymonitoring", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetBinCardReports({ commit, rootGetters }) {
            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/bincards", {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        //commit('SET_BinCard', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        UploadBinCard({ rootGetters }, payload) {
            let formData = new FormData();
            formData.append('file', payload);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Upload/bincards", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetSalesReports({ commit, rootGetters }) {
            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/summary", {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        //commit('SET_SalesReport', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        UploadSalesReport({ rootGetters }, payload) {
            let formData = new FormData();
            formData.append('file', payload);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Upload/v1/salestransaction", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        UploadSalesReportV2({ rootGetters }, payload) {
            let formData = new FormData();
            formData.append('file', payload);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Upload/v2/salestransaction", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetStockOnHandReports({ getters, rootGetters }) {
            var filter = getters.stockOnHandReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/stockonhand?isofficial=" + filter.isOfficial + "&principalcode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        //commit('SET_StockOnHand', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetStockOnHandFieldforceReports({ getters, rootGetters }) {
            var filter = getters.stockOnHandReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/stockonhand/fieldforce?isofficial=" + filter.isOfficial + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        //commit('SET_StockOnHand', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetStockOnHandDMReports({ getters, rootGetters }) {
            var filter = getters.stockOnHandReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/stockonhand/dm?isofficial=" + filter.isOfficial + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        //commit('SET_StockOnHand', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GetStockOnHandNSMReports({ getters, rootGetters }) {
            var filter = getters.stockOnHandReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/Report/stockonhand/nsm?isofficial=" + filter.isOfficial + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        //commit('SET_StockOnHand', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        UploadStockOnHandV2({ rootGetters }, payload) {
            let formData = new FormData();
            formData.append('file', payload);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Upload/stockonhand", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateInvoiceListing({ getters, rootGetters }) {
            var filter = getters.invoiceListingReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/invoicelisting?isofficial=" + filter.isOfficial + "&principalcode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateAgentInvoiceListing({ getters, rootGetters }) {
            var filter = getters.invoiceListingReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/invoicelisting/agent?isofficial=" + filter.isOfficial + "&agentCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateDMInvoiceListing({ getters, rootGetters }) {
            var filter = getters.invoiceListingReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/invoicelisting/dm?isofficial=" + filter.isOfficial + "&dmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateNSMInvoiceListing({ getters, rootGetters }) {
            var filter = getters.invoiceListingReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/invoicelisting/nsm?isofficial=" + filter.isOfficial + "&nsmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateSalesSummary({ getters, rootGetters }) {
            var filter = getters.salesSummaryReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/salessummary?isofficial=" + filter.isOfficial + "&principalcode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateAgentSalesSummary({ getters, rootGetters }) {
            var filter = getters.salesSummaryReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/salessummary/agent?isofficial=" + filter.isOfficial + "&agentCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateDMSalesSummary({ getters, rootGetters }) {
            var filter = getters.salesSummaryReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/salessummary/dm?isofficial=" + filter.isOfficial + "&dmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateNSMSalesSummary({ getters, rootGetters }) {
            var filter = getters.salesSummaryReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/salessummary/nsm?isofficial=" + filter.isOfficial + "&nsmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateSalesReturn({ getters, rootGetters }) {
            var filter = getters.salesReturnReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/returns?isofficial=" + filter.isOfficial + "&principalcode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateAgentSalesReturn({ getters, rootGetters }) {
            var filter = getters.salesReturnReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/returns/agent?isofficial=" + filter.isOfficial + "&agentCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateDMSalesReturn({ getters, rootGetters }) {
            var filter = getters.salesReturnReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/returns/dm?isofficial=" + filter.isOfficial + "&dmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateNSMSalesReturn({ getters, rootGetters }) {
            var filter = getters.salesReturnReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/returns/nsm?isofficial=" + filter.isOfficial + "&nsmCode=" + filter.principalCode + "&from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        GenerateInvoiceLisingValidation({ getters, rootGetters }) {
            var filter = getters.invoiceListingReportFilter;

            return new Promise((resolve, reject) => {
                instance
                    .get("api/report/salestransaction/invoicelisting/validation?from=" + filter.dateFrom + "&to=" + filter.dateTo, {
                        responseType: 'blob',
                        headers: {
                            Authorization: "Bearer " + rootGetters.token
                        }
                    })
                    .then(async function(response) {
                        var filename = response.headers['content-disposition'].split("; ")[1].split('=')[1].replace(/"/g, '');
                        const url = URL.createObjectURL(new Blob([response.data], {
                            type: 'application/octet-stream'
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();

                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        }
    },
    modules: {},
};