<template>
  <div class="customer-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">National Sales Manager Maintenance</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <label>National Sales Manager Code:</label>
                      <input type="text" class="form-control" placeholder="NSM Code" v-model="filter.nsmCode"
                        :disabled="isSearching" />
                    </div>
                    <div class="col">
                      <p style="margin-bottom: 0.3rem">&nbsp;</p>
                      <button type="button" class="btn btn-primary" @click="searchFilter" :disabled="isSearching">
                        <i class="fas fa-search"></i>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-right">
                  <p style="margin-bottom: 0rem">&nbsp;</p>
                  <button type="button" class="btn btn-primary btn-sm-block mt-1">
                    <i class="fas fa-download"></i>
                    Export National Sales Manager
                  </button>
                  <button type="button" class="btn btn-primary btn-sm-block mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                    :disabled="isSearching" @click="addNewNSM">
                    <i class="fas fa-user-plus"></i>
                    Add National Sales Manager
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>NSM Code</th>
                    <th>NSM Name</th>
                    <th>Agents</th>
                    <th>Email/s</th>
                    <th class="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="nationalSalesPageItems.length == 0 && !isSearching">
                    <td colspan="10">
                      <div>
                        <lottie-animation style="height: 500px" :loop="true" :animationData="require('@/assets/lottie/no-data.json')
                        " />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="isSearching">
                    <td colspan="10">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(nsm, i) in nationalSalesPageItems" :key="i">
                    <td>{{ nsm.nsmCode }}</td>
                    <td>{{ nsm.nsmName }}</td>
                    <td>
                      <a href="#" @click="selectNSMAgent(nsm)">
                        Agents
                      </a>
                    </td>
                    <td>{{ nsm.listEmail.toString() }}</td>
                    <td class="text-center">
                      <!-- <a href="#" @click="showUserAccess(district.dmCode)">
                        Users
                      </a>
                      | -->
                      <a href="#" @click="selectNSM(nsm)"> Edit </a>
                      |
                      <a href="#" @click="deleteNSM(nsm)">Delete</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination v-if="nationalSalesPageItems.length != 0" v-model="currentPage" :total-rows="rows"
              :per-page="pageSize" aria-controls="my-table" @change="paginationChanged"
              style="float: right; margin-top: 20px"></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="nsmAgentsModalShow" size="md" title="NSM Agents" hide-footer no-close-on-backdrop>
      <p>
        NSM Code: <b>{{ nsmCode }}</b>
      </p>
      <hr />
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Agent Code</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="nsmAgents.length == 0">
            <td colspan="1">
              <div>
                <lottie-animation style="height: 500px" :loop="true"
                  :animationData="require('@/assets/lottie/no-data.json')" />
              </div>
            </td>
          </tr>
          <tr v-for="(agent, i) in nsmAgents" :key="i">
            <td>{{ agent }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <b-modal v-model="nsmModalShow" size="xl" title="NSM Details" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtNSMCode" ref="modalNSMDetails"> NSM Code </label>
              <input type="text" class="form-control" id="txtNSMCode" placeholder="NSM Code"
                :class="{ 'is-invalid': $v.nationalSalesManager.nsmCode.$error, }"
                v-model="$v.nationalSalesManager.nsmCode.$model" :disabled="isEdit" />
              <div class="form-error" v-if="$v.nationalSalesManager.nsmCode.$error">
                NSM Code is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtEmails">Agent Codes</label>
              <multiselect v-model="listAgentCodes" placeholder="Add agent code/s" label="name" track-by="code"
                :class="{ 'is-invalid': $v.nationalSalesManager.listAgentCodes.$error }" :options="agentOptions"
                :multiple="true" no-options-text="No agent available">
              </multiselect>
              <div class="form-error" v-if="$v.nationalSalesManager.listAgentCodes.$error">
                Emails is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtNSMName" ref="modalNSMDetails"> NSM Name </label>
              <input type="text" class="form-control" id="txtNSMName" placeholder="NSM Name" :class="{
                        'is-invalid': $v.nationalSalesManager.nsmName.$error,
                      }" v-model="$v.nationalSalesManager.nsmName.$model" />
              <div class="form-error" v-if="$v.nationalSalesManager.nsmName.$error">
                NSM Name is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtEmails">Emails</label>
              <multiselect v-model="listEmail" tag-placeholder="Add this email" placeholder="Add email" label="name"
                track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addEmailTag"
                @remove="removeTag" :class="{
                        'is-invalid': $v.nationalSalesManager.listEmail.$error,
                      }"></multiselect>
              <div class="form-error" v-if="$v.nationalSalesManager.listEmail.$error">
                Emails is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="submitNationalSalesManager">
              Submit
            </button>
            <button type="button" class="btn btn-outline-light" @click="closeModal">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- 
  
      <b-sidebar id="sidebar-backdrop" backdrop shadow right width="1000px" no-header-close v-model="showSidebar"
        @change="changeSidebarStatus">
        <div class="px-3 py-3">
          <div class="row">
            <div class="col">
              <h3><strong>DM User Access</strong></h3>
            </div>
            <div class="col text-right">
              <button type="button" class="btn btn-primary mr-2" @click="addDMAccessModalShow = true">
                Add User
              </button>
            </div>
          </div>
  
          <hr style="opacity: 0.08" />
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Report Access</th>
                <th>Date Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dm, i) in lstDMAccess" :key="i">
                <td>{{ dm.name }}</td>
                <td>{{ dm.email }}</td>
                <td>{{ dm.reportAccess }}</td>
                <td>
                  {{
                    new Date(dm.dateCreated).toLocaleDateString('en-US', {
                      month: 'short',
                      day: "numeric",
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })
                  }}
                </td>
                <td>
                  <a href="#" @click="
                  resetDMAccess(dm.email);
                  isEdit = true;
                  ">
                    Reset
                  </a>
                  |
                  <a href="#" @click="deleteDMAccess(dm.email)">Delete</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-sidebar>
  
      <b-modal
        v-model="addDMAccessModalShow"
        size="md"
        title="Add DM Access"
        hide-footer
        no-close-on-backdrop
      >
        <form class="forms-sample">
          <div class="row">
            <div class="col">
              <p>
                DM Code:
                <strong>{{ dmAccess.dmCode }}</strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="txtFirstName">Name</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="dmAccess.name"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="txtFirstName">Email</label>
                <input
                  class="form-control"
                  type="text"
                  v-model="dmAccess.email"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="txtFirstName">Report Access</label>
                <select
                  class="form-control"
                  v-model="dmAccess.reportAccess"
                >
                  <option value="Delivery Monitoring">Delivery Monitoring</option>
                  <option value="Stock On Hand">Stock On Hand</option>
                  <option value="Sales Report">Sales Report</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <button
                type="button"
                class="btn btn-primary mr-2"
                @click="AddDMAccess"
              >
                Submit
              </button>
              <button
                type="button"
                class="btn btn-outline-light"
                @click="
                  addDMAccessModalShow = !addDMAccessModalShow
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </b-modal> -->
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { required } from "vuelidate/lib/validators";
import nationalSalesController from '@/store/nationalSalesController';

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      addDMAccessModalShow: false,
      showSidebar: false,
      file: null,
      nationalSalesPageItems: [],
      options: [],
      nsmModalShow: false,
      nsmAgentsModalShow: false,
      pageSize: 50,
      currentPage: 1,
      isSearching: false,
      isDownloading: false,
      isEdit: false,
      listEmail: [],
      listAgentCodes: [],
      nsmCode: "",
      nsmAgents: []
    };
  },
  validations: {
    nationalSalesManager: {
      nsmCode: {
        required,
      },
      nsmName: {
        required,
      },
      listAgentCodes: {
        required,
      },
      listEmail: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      nationalSales: (state) => state.nationalSalesController.nationalSales,
      filter: (state) => state.nationalSalesController.filter,
      nationalSalesManager: (state) => state.nationalSalesController.nationalSalesManager,
      agents: (state) => state.territoryController.territories,
      // districtAgents: (state) => state.districtController.districtAgents,
      // lstDMAccess: (state) => state.districtController.lstDMAccess,
      // dmAccess: (state) => state.districtController.dmAccess,
    }),
    agentOptions() {
      return this.agents.filter(m => m.nsmCode == null).map((mg) => ({
        ["name"]: mg.agentCode,
        ["code"]: mg.agentCode + "-001",
      }));
    },
    rows() {
      return this.nationalSales.length;
    },
  },
  methods: {
    ...mapMutations({
      setNationalSalesManager: "nationalSalesController/SET_NationalSalesManager",
      resetNationalSalesManager: "nationalSalesController/RESET_NationalSalesManager",
    }),
    // showUserAccess: async function (dmCode) {
    //   await this.$store.dispatch("showOverlay", true);
    //   var result = await this.$store
    //     .dispatch("districtController/GetDMAccess", dmCode)
    //     .catch(async (error) => {
    //       await this.$store.dispatch("showOverlay", false);
    //       this.$toast.open({
    //         message: error.data,
    //         type: "error",
    //       });
    //     });

    //   if (result) {
    //     await this.$store.dispatch("showOverlay", false);
    //     this.dmAccess.dmCode = dmCode;
    //     this.showSidebar = true;
    //   }
    // },
    // changeSidebarStatus: function (value) {
    //   this.showSidebar = value;
    // },    
    // AddDMAccess: async function () {
    //   await this.$store.dispatch("showOverlay", true);
    //   var result = await this.$store
    //     .dispatch("districtController/AddDMAccess")
    //     .catch(async (error) => {
    //       await this.$store.dispatch("showOverlay", false);
    //       this.$toast.open({
    //         message: error.data,
    //         type: "error",
    //       });
    //     });

    //   if (result) {
    //     await this.$store.dispatch("showOverlay", false);
    //     this.addDMAccessModalShow = false;
    //     this.$toast.open({
    //       message: "DM user access has been successfully added.",
    //       type: "success",
    //     });
    //   }
    // },

    // deleteDMAccess: async function (email) {
    //   if (confirm("Are you sure you want to delete this dm access?")) {
    //     await this.$store.dispatch("showOverlay", true);
    //     var result = await this.$store
    //       .dispatch("districtController/DeleteDMAccess", email)
    //       .catch(async (error) => {
    //         await this.$store.dispatch("showOverlay", false);
    //         this.$toast.open({
    //           message: error.data,
    //           type: "error",
    //         });
    //       });

    //     if (result) {
    //       await this.$store.dispatch("showOverlay", false);
    //       this.$toast.open({
    //         message: "DM user access has been successfully deleted.",
    //         type: "success",
    //       });
    //     }
    //   }
    // },
    // resetDMAccess: async function (email) {
    //   if (confirm("Are you sure you want to reset password?")) {
    //     await this.$store.dispatch("showOverlay", true);
    //     var result = await this.$store
    //       .dispatch("districtController/ResetDMAccess", email)
    //       .catch(async (error) => {
    //         await this.$store.dispatch("showOverlay", false);
    //         this.$toast.open({
    //           message: error.data,
    //           type: "error",
    //         });
    //       });

    //     if (result) {
    //       await this.$store.dispatch("showOverlay", false);
    //       this.addDMAccessModalShow = false;
    //       this.$toast.open({
    //         message: "DM user access has been successfully reset.",
    //         type: "success",
    //       });
    //     }
    //   }
    // },
    addNewNSM: async function name() {
      this.isEdit = false;
      this.listEmail = [];
      this.listAgentCodes = [];

      await this.resetNationalSalesManager();
      this.nsmModalShow = !this.nsmModalShow;
    },
    addEmailTag: function (email) {
      const tag = {
        name: email,
        code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };

      this.options.push(tag);
      this.listEmail.push(tag);
    },
    removeTag: function (removedOption) {
      for (var i = 0; i < this.nationalSalesManager.listEmail.length; i++) {
        var obj = this.nationalSalesManager.listEmail[i];

        if (obj == removedOption.name) {
          this.nationalSalesManager.listEmail.splice(i, 1);
        }
      }
    },
    deleteNSM: async function (nsm) {
      await this.setNationalSalesManager(nsm);
      var self = this;
      self.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete " + nsm.nsmCode + "?",
          {
            title: "Delete DM",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await self.$store.dispatch("showOverlay", true);

            var result = await self.$store
              .dispatch("nationalSalesController/DeleteNationalSalesManager")
              .catch(async (error) => {
                await self.$store.dispatch("showOverlay", false);
              });

            if (result) {
              await self.searchFilter();
              await self.$store.dispatch("showOverlay", false);
            }
          }
        })
        .catch((err) => { });
    },
    paginationChanged: function (page) {
      if (page == null) {
        page = 1;
      }

      this.nationalSalesPageItems = this.nationalSales.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.nationalSalesPageItems = [];
      this.isSearching = true;

      await this.$store
        .dispatch("territoryController/GetTerritories")
        .catch((error) => { });

      var result = await this.$store
        .dispatch("nationalSalesController/GetNationalSales")
        .catch((error) => {
          this.isSearching = false;
        });

      if (result) {
        this.paginationChanged(this.currentPage);
        this.isSearching = false;
      }
    },
    selectNSMAgent(nsm) {
      this.nsmCode = nsm.nsmCode
      this.nsmAgents = nsm.listAgentCodes
      this.nsmAgentsModalShow = true;
    },
    selectNSM: async function name(nsm) {
      this.isEdit = true;
      this.listEmail = [];
      this.listAgentCodes = [];

      console.log(this.nationalSalesManager)
      await this.setNationalSalesManager(Object.assign({}, nsm));
      this.nationalSalesManager.listEmail.forEach((email) => {
        this.listEmail.push({
          name: email,
          code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
        });
      });
      this.nationalSalesManager.listAgentCodes.forEach((agentCode) => {
        this.listAgentCodes.push({
          name: agentCode,
          code: agentCode.substring(0, 2) + Math.floor(Math.random() * 10000000),
        });
      });

      this.nsmModalShow = true;
    },
    submitNationalSalesManager: async function () {
      this.$v.nationalSalesManager.$touch();
      this.nationalSalesManager.listEmail = this.listEmail.reduce(
        (a, o) => (o.name && a.push(o.name), a),
        []
      );
      this.nationalSalesManager.listAgentCodes = this.listAgentCodes.reduce(
        (a, o) => (o.name && a.push(o.name), a),
        []
      );

      if (!this.$v.nationalSalesManager.$invalid) {
        await this.$store.dispatch("showOverlay", true);

        if (this.isEdit) {
          var result = await this.$store
            .dispatch("nationalSalesController/UpdateNationalSalesManager")
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
            });

          if (result) {
            this.$v.nationalSalesManager.$reset();
            await this.searchFilter();
            this.nsmModalShow = false;
            await this.$store.dispatch("showOverlay", false);
          }
        } else {
          var result = await this.$store
            .dispatch("nationalSalesController/AddNationalSalesManager")
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
            });

          if (result) {
            await this.searchFilter();
            this.$v.nationalSalesManager.$reset();
            this.nsmModalShow = false;
            await this.$store.dispatch("showOverlay", false);
          }
        }
      }
    },
    closeModal: async function () {
      await this.searchFilter();
      this.nsmModalShow = !this.nsmModalShow;
      this.$v.$reset();
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style></style>