<template>
  <div class="dashboard-container">
    <!-- <h3>Welcome {{ admin.name }}!</h3> -->

    <div style="min-height: calc(80vh - 60px); position: relative">
      <lottie-animation
        style="
          height: 500px;
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        "
        :loop="true"
        :animationData="require('@/assets/lottie/welcome.json')"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
  computed: {
    ...mapState({
      admin: (state) => state.admin.info,
    }),
  },
};
</script>

<style>
</style>