<template>
  <div class="pdc-collection-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">PDC Collection Report</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <button type="button" class="
                          btn btn-primary btn-sm-block
                          mt-1
                          ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2
                        " @click="uploadModalShow = true">
                        <i class="fas fa-upload"></i>
                        Upload PDC Collection Raw File
                      </button>
                    </div>
                  </div>
                </div>
                <div class="
                    col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12
                    text-right
                  ">
                  <button type="button" class="
                      btn btn-primary btn-sm-block
                      mt-1
                      ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2
                    " @click="generatePDCCollectionModal = true">
                    <i class="fas fa-file-export"></i>
                    Generate PDC Collection Report
                  </button>
                </div>
              </div>
            </div>
            <div>
              <lottie-animation style="height: 500px" :loop="true"
                :animationData="require('@/assets/lottie/report.json')" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- upload modal -->
    <b-modal v-model="uploadModalShow" size="md" title="PDC Collection Report" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Raw File</label>
              <input class="form-control" type="file" v-on:change="handleFileUpload($event)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="uploadFile">
              Upload
            </button>
            <button type="button" class="btn btn-light" @click="uploadModalShow = !uploadModalShow">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- generate report modal -->
    <b-modal v-model="generatePDCCollectionModal" size="md" title="PDC Collection Report" hide-footer
      no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="chkReportType">Report Type</label>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="agent" v-model="reportType"
                        :disabled="isGenerating" />
                      Agent Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="district" v-model="reportType"
                        :disabled="isGenerating" />
                      DM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="nationalsales" v-model="reportType"
                        :disabled="isGenerating" />
                      NSM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'agent'">
          <div class="col">
            <div class="form-group">
              <label for="txtAgentCode">Agent Code</label>
              <multiselect id="txtAgentCode" v-model="value" placeholder="Search Agent Code" label="name" track-by="code"
                :options="agentOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'district'">
          <div class="col">
            <div class="form-group">
              <label for="txtDMCode">DM Code</label>
              <multiselect id="txtDMCode" v-model="value" placeholder="Search DM Code" label="name" track-by="code"
                :options="dmOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'nationalsales'">
          <div class="col">
            <div class="form-group">
              <label for="txtNSMCode">NSM Code</label>
              <multiselect id="txtNSMCode" v-model="value" placeholder="Search NSM Code" label="name" track-by="code"
                :options="nsmOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'principal'">
          <div class="col">
            <div class="form-group">
              <label for="txtPrincipalCode">Principal Code</label>
              <multiselect id="txtPrincipalCode" v-model="value" placeholder="Search Principal Code" label="name"
                track-by="code" :options="principalOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtReportDateRange">Report Date Range</label>
              <date-picker
                v-model="PDCCollectionReportFilter.dateRange"
                :clearable="false"
                format="MM/DD/YYYY"
                title-format="MM/DD/YYYY"
                time-title-format="MM/DD/YYYY"
                input-class="form-control"
                :input-attr="{ id: 'txtReportDateRange' }"
                :disabled="isGenerating"
                range
              ></date-picker>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div class="col">
            <div class="form-check form-check-flat form-check-primary">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="PDCCollectionReportFilter.isOfficial"
                  :disabled="isGenerating" />
                Is Official
                <i class="input-helper"></i></label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="generatePDCCollectionReport"
              :disabled="isGenerating">
              <i class="fas fa-spinner fa-spin mr-1" v-if="isGenerating" />
              Generate
            </button>
            <button type="button" class="btn btn-light" @click="
              generatePDCCollectionModal =
              !generatePDCCollectionModal
              " :disabled="isGenerating">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      value: [],
      reportType: "agent",
      reportDateRange: [new Date(), new Date()],
      generatePDCCollectionModal: false,
      uploadModalShow: false,
      pageSize: 15,
      currentPage: 1,
      pageOfItems: [],
      isSearching: false,
      hasNoRecord: false,
      emailError: "",
      passwordError: "",
      isEdit: false,
      isGenerating: false,
      file: null,
    };
  },
  computed: {
    ...mapState({
      principals: (state) => state.principalController.principals,
      agents: (state) => state.territoryController.territories,
      districts: (state) => state.districtController.districts,
      nationalSales: (state) => state.nationalSalesController.nationalSales,
      PDCCollectionReports: (state) =>
        state.reportController.PDCCollectionReports,
      PDCCollectionReportFilter: (state) =>
        state.reportController.PDCCollectionReportFilter,
    }),
    rows() {
      return this.PDCCollectionReports.length;
    },
    agentOptions() {
      return this.agents.map((mg) => ({
        ["name"]: mg.agentCode,
        ["code"]: mg.agentCode + "-001",
      }));
    },
    principalOptions() {
      return this.principals.map((mg) => ({
        ["name"]: mg.principalCode,
        ["code"]: mg.principalCode + "-001",
      }));
    },
    dmOptions() {
      return this.districts.map((mg) => ({
        ["name"]: mg.dmCode,
        ["code"]: mg.dmCode + "-001",
      }));
    },
    nsmOptions() {
      return this.nationalSales.map((mg) => ({
        ["name"]: mg.nsmCode,
        ["code"]: mg.nsmCode + "-001",
      }));
    },
  },
  watch: {
    reportType: {
      handler() {
        this.value = [];
      },
      deep: true,
    },
  },
  validations: {
    PDCCollectionReportFilter: {
      dateRange: {
        required,
      },
    },
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    generateReport() {
      this.$toast.open({
        message: "This feature is not yet implemented.",
        type: "info",
      });
    },
    async generatePDCCollectionReport() {
      this.$v.$touch();

      if (!this.$v.PDCCollectionReportFilter.$invalid) {
        this.isGenerating = true;
        this.PDCCollectionReportFilter.principalCode = this.value
          .map((mg) => mg.name)
          .join("~");

        var result = false;

        if (this.reportType == "agent") {
          result = await this.$store
            .dispatch("reportController/GetPDCCollectionReports")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "district") {
          result = await this.$store
            .dispatch("reportController/GetPDCCollectionDMReports")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "nationalsales") {
          result = await this.$store
            .dispatch("reportController/GetPDCCollectionNSMReports")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else {
          result = await this.$store
            .dispatch("reportController/GetPDCCollectionReports")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        }

        if (result) {
          this.generatePDCCollectionModal = false;
          this.reportType = "agent";
          this.isGenerating = false;
          this.value = [];

          this.$toast.open({
            message: "Report has been saved.",
            type: "success",
          });
        }
      }
    },
    async uploadFile() {
      await this.$store.dispatch("showOverlay", true);
      var result = await this.$store
        .dispatch("reportController/UploadPDCCollection", this.file)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data,
            type: "error",
          });
        });

      if (result) {
        await this.searchFilter();
        await this.$store.dispatch("showOverlay", false);
        this.uploadModalShow = false;
        this.$toast.open({
          message: "Report has been uploaded.",
          type: "success",
        });
      }
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.pageOfItems = [];
      this.isSearching = true;
      this.hasNoRecord = false;

      if (true) {
        this.isSearching = false;

        if (this.PDCCollectionReports.length == 0) {
          this.hasNoRecord = true;
        }
      }
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style scoped>
.mx-datepicker-range {
  width: 100%;
}
</style>