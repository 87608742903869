<template>
  <div class="bin-card-monitoring-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">BIN Card Reports</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <button type="button" class="btn btn-primary btn-sm-block mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                        @click="uploadModalShow = true">
                        <i class="fas fa-upload"></i>
                        Upload BIN Card Raw File
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 text-right">
                  <button type="button" class="btn btn-primary btn-sm-block mt-1 ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                    @click="stockOnHandReportModal = true">
                    <i class="fas fa-file-export"></i>
                    Generate Stock On Hand Report
                  </button>
                </div>
              </div>
            </div>
            <div>
              <lottie-animation style="height: 500px" :loop="true"
                :animationData="require('@/assets/lottie/report.json')" />
            </div>
            <!-- <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Warehouse</th>
                    <th>Principal</th>
                    <th>Item Code</th>
                    <th>Item Description</th>
                    <th>Unit of Measure</th>
                    <th>Warehouse Location</th>
                    <th>Expiry Date</th>
                    <th>Status</th>
                    <th>Lot Number</th>
                    <th>Receipts</th>
                    <th>Issues</th>
                    <th>Adjustments</th>
                    <th>Returns</th>
                    <th>Sales</th>
                    <th>Transfer</th>
                    <th>Current</th>
                    <th>Stock Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="hasNoRecord">
                    <td colspan="17">
                      <div>
                        <lottie-animation
                          style="height: 500px"
                          :loop="true"
                          :animationData="
                            require('@/assets/lottie/no-data.json')
                          "
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="isSearching">
                    <td colspan="17">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(binCard, i) in binCardReportPageItems" :key="i">
                    <td>{{ binCard.warehouse }}</td>
                    <td>{{ binCard.principal }}</td>
                    <td>{{ binCard.itemCode }}</td>
                    <td>{{ binCard.itemDescription }}</td>
                    <td>{{ binCard.unitOfMeasure }}</td>
                    <td>{{ binCard.warehouseLocation }}</td>
                    <td>{{ binCard.expiryDate }}</td>
                    <td>{{ binCard.status }}</td>
                    <td>{{ binCard.lotNumber }}</td>
                    <td>{{ binCard.receipts }}</td>
                    <td>{{ binCard.issues }}</td>
                    <td>{{ binCard.adjustments }}</td>
                    <td>{{ binCard.returns }}</td>
                    <td>{{ binCard.sales }}</td>
                    <td>{{ binCard.transfer }}</td>
                    <td>{{ binCard.current }}</td>
                    <td>{{ binCard.stockValue }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination
              v-if="binCardReportPageItems.length != 0"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="pageSize"
              aria-controls="my-table"
              @change="paginationChanged"
              style="float: right; margin-top: 20px"
            ></b-pagination> -->
          </div>
        </div>
      </div>
    </div>

    <!-- upload modal -->
    <b-modal v-model="uploadModalShow" size="md" title="BIN Card" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="form-group">
          <label for="chkReportVersion">Report Version</label>
          <div class="row">
            <div class="col">
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="radio"
                    class="form-check-input"
                    value="1"
                    v-model="reportVersion"
                    :disabled="isGenerating" />
                  V1
                  <i class="input-helper"></i
                ></label>
              </div>
            </div>
            <!--<div class="col">
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="radio"
                    class="form-check-input"
                    value="2"
                    v-model="reportVersion"
                    :disabled="isGenerating" />
                  V2
                  <i class="input-helper"></i
                ></label>
              </div>
            </div>-->
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Raw File</label>
              <input class="form-control" type="file" v-on:change="handleFileUpload($event)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="uploadFile">
              Upload
            </button>
            <button type="button" class="btn btn-light" @click="uploadModalShow = !uploadModalShow">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- generate report modal -->
    <b-modal v-model="stockOnHandReportModal" size="md" title="Stock On Hand Report" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="chkReportType">Report Type</label>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="principal" v-model="reportType"
                        :disabled="isGenerating" />
                      Principal Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="fieldforce" v-model="reportType"
                        :disabled="isGenerating" />
                      Field Force
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="district" v-model="reportType"
                        :disabled="isGenerating" />
                      DM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="nationalsales" v-model="reportType"
                        :disabled="isGenerating" />
                      NSM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtReportDateRange">Report Date</label>
              <date-picker v-model="stockOnHandReportFilter.date" :clearable="false" format="MM/DD/YYYY"
                title-format="MM/DD/YYYY" time-title-format="MM/DD/YYYY" input-class="form-control"
                :input-attr="{ id: 'txtReportDateRange' }" :disabled="isGenerating" single></date-picker>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'principal'">
          <div class="col">
            <div class="form-group">
              <label for="txtPrincipalCode">Principal Code</label>
              <multiselect id="txtPrincipalCode" v-model="value" placeholder="Search Principal Code" label="name"
                track-by="code" :options="principalOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check form-check-flat form-check-primary">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="stockOnHandReportFilter.isOfficial"
                  :disabled="isGenerating" />
                Is Official
                <i class="input-helper"></i></label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="generateStockOnHandReport"
              :disabled="isGenerating">
              <i class="fas fa-spinner fa-spin mr-1" v-if="isGenerating" />
              Generate
            </button>
            <button type="button" class="btn btn-light" @click="
                          stockOnHandReportModal = !stockOnHandReportModal;
                        reportType = 'principal';
                        " :disabled="isGenerating">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      value: [],
      reportType: "principal",
      reportDateRange: [new Date(), new Date()],
      binCardReportPageItems: [],
      stockOnHandReportModal: false,
      uploadModalShow: false,
      pageSize: 15,
      currentPage: 1,
      pageOfItems: [],
      isSearching: false,
      hasNoRecord: false,
      emailError: "",
      passwordError: "",
      isEdit: false,
      isGenerating: false,
      reportVersion: "1",
      file: null,
    };
  },
  computed: {
    ...mapState({
      principals: (state) => state.principalController.principals,
      binCardReports: (state) => state.reportController.binCardReports,
      stockOnHandReportFilter: (state) =>
        state.reportController.stockOnHandReportFilter,
    }),
    rows() {
      return this.binCardReports.length;
    },
    principalOptions() {
      return this.principals.map((mg) => ({
        ["name"]: mg.principalCode,
        ["code"]: mg.principalCode + "-001",
      }));
    },
  },
  validations: {
    stockOnHandReportFilter: {
      // dateRange: {
      //   required,
      // },
    },
  },
  watch: {
    reportType: {
      handler() {
        this.value = [];
      },
      deep: true,
    },
  },
  methods: {
    paginationChanged: function (page) {
      if (page == null) {
        page = 1;
      }

      this.binCardReportPageItems = this.binCardReports.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    generateReport() {
      this.$toast.open({
        message: "This feature is not yet implemented.",
        type: "info",
      });
    },
    async generateStockOnHandReport() {
      this.$v.$touch();

      if (!this.$v.stockOnHandReportFilter.$invalid) {
        this.isGenerating = true;
        this.stockOnHandReportFilter.principalCode = this.value
          .map((mg) => mg.name)
          .join("~");

        var result = false;

        if (this.reportType == "fieldforce") {
          result = await this.$store
            .dispatch("reportController/GetStockOnHandFieldforceReports")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "district") {
          result = await this.$store
            .dispatch("reportController/GetStockOnHandDMReports")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "nationalsales") {
          result = await this.$store
            .dispatch("reportController/GetStockOnHandNSMReports")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else {
          result = await this.$store
            .dispatch("reportController/GetStockOnHandReports")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        }

        if (result) {
          this.stockOnHandReportModal = false;
          this.reportType = "principal";
          this.isGenerating = false;
          this.value = [];

          this.$toast.open({
            message: "Report has been saved.",
            type: "success",
          });
        }
      }
    },
    async uploadFile() {
      var result = false;
      await this.$store.dispatch("showOverlay", true);

      switch (this.reportVersion) {
        case "1":
          result = await this.$store
            .dispatch("reportController/UploadBinCard", this.file)
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data,
                type: "error",
              });
            });
          break;

        case "2":
          result = await this.$store
            .dispatch("reportController/UploadStockOnHandV2", this.file)
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data,
                type: "error",
              });
            });
          break;
      }

      if (result) {
        await this.searchFilter();
        await this.$store.dispatch("showOverlay", false);
        this.uploadModalShow = false;
        this.$toast.open({
          message: "Report has been uploaded.",
          type: "success",
        });
      }
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.pageOfItems = [];
      this.isSearching = true;
      this.hasNoRecord = false;
      // var result = await this.$store
      //   .dispatch("reportController/GetBinCardReports")
      //   .catch((error) => {
      //     this.hasNoRecord = true;
      //     this.isSearching = false;
      //   });

      // if (result) {
      if (true) {
        this.paginationChanged();
        this.isSearching = false;

        if (this.binCardReports.length == 0) {
          this.hasNoRecord = true;
        }
      }
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style scoped>
.mx-datepicker-range {
  width: 100%;
}
</style>