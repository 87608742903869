import axios from "axios";
import Vue from "vue";

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        districts: [],
        districtAgents: [],
        lstDMAccess: [],
        dmAccess: {
            dmCode: '',
            name: '',
            email: '',
            reportAccess: '',
        },
        district: {
            districtID: 0,
            dmCode: "",
            dmName: "",
            designation: "",
            listEmail: [],
            dateCreated: new Date()
        },
        filter: {
            dmCode: ""
        }
    },
    mutations: {
        SET_Districts(state, value) {
            state.districts = value
        },
        SET_DistrictAgents(state, value) {
            state.districtAgents = value
        },
        SET_District(state, value) {
            state.district = Object.assign({}, value);
        },
        RESET_DistrictAgents(state) {
            state.districtAgents = []
        },
        RESET_District(state) {
            state.district = {
                districtID: 0,
                dmCode: "",
                dmName: "",
                designation: "",
                listEmail: [],
                dateCreated: new Date()
            }
        },
        RESET_Districts(state) {
            state.districts = []
        },
        SET_LstDMAccess(state, data) {
            state.lstDMAccess = data
        },
        RESET_LstDMAccess(state) {
            state.lstDMAccess = []
        },
        RESET_DMAccess(state) {
            state.dmAccess = {
                dmCode: '',
                name: '',
                email: '',
                reportAccess: '',
            }
        },
        UPDATE_DMAccess(state, data) {
            var index = state.lstDMAccess.findIndex((obj => obj.email == data.email));

            if (index >= 0) {
                Vue.set(state.lstDMAccess, index, data);
            } else {
                state.lstDMAccess.push(data);
            }
        },
        REMOVE_DMAccess(state, email) {
            var index = state.lstDMAccess.findIndex((obj => obj.email == email));

            if (index >= 0) {
                state.lstDMAccess.splice(index, 1);
            }
        },
    },
    getters: {
        filter(state) {
            return state.filter
        },
        district(state) {
            return state.district
        },
        dmAccess(state) {
            return state.dmAccess
        }
    },
    actions: {
        GetDistricts({ getters, commit, rootGetters }) {
            var filter = getters.filter;
            commit('RESET_Districts');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/district?dmCode=" + filter.dmCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Districts', response.data)
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        GetDistrictsAgents({ getters, commit, rootGetters }, dmCode) {
            commit('RESET_DistrictAgents');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/district/agents?dmCode=" + dmCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_DistrictAgents', response.data);
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        AddDistrict({ getters, rootGetters }) {
            var district = getters.district;
            district.listEmail = district.listEmail.map(m => m.name);
            console.log(district)
            return new Promise((resolve, reject) => {
                instance
                    .post("api/district", district, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        UpdateDistrict({ getters, rootGetters }) {
            var district = getters.district;
            //district.listEmail = district.listEmail.map(m => m.name);
            console.log(district)
            return new Promise((resolve, reject) => {
                instance
                    .put("api/district/" + district.districtID, district, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        DeleteDistrict({ getters, rootGetters }) {
            var district = getters.district;

            return new Promise((resolve, reject) => {
                instance
                    .delete("api/district/" + district.districtID, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        
        GetDMAccess({ commit, rootGetters }, dmCode) {
            commit('RESET_LstDMAccess');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/district/access?dmcode=" + dmCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('SET_LstDMAccess', response.data)
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        ResetDMAccess({ commit, rootGetters }, email) {
            return new Promise((resolve, reject) => {
                instance
                    .put("api/district/access/reset/password?email=" + email, '', {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('RESET_DMAccess');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        DeleteDMAccess({ commit, rootGetters }, email) {
            return new Promise((resolve, reject) => {
                instance
                    .delete("api/district/access?email=" + email, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('REMOVE_DMAccess', email)
                        commit('RESET_DMAccess');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
        AddDMAccess({ getters, commit, rootGetters }) {
            var dmAccess = getters.dmAccess;

            return new Promise((resolve, reject) => {
                instance
                    .post("api/district/access", dmAccess, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function(response) {
                        commit('UPDATE_DMAccess', response.data)
                        commit('RESET_DMAccess');
                        resolve(true);
                    })
                    .catch(function(error) {
                        reject(error.response);
                    });
            });
        },
    },
};