<template>
  <div class="territory-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Agent Maintenance</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <label>Agent Code:</label>
                      <input type="text" class="form-control" v-model="filter.agentCode" placeholder="Territory Code"
                        :disabled="isSearching" />
                    </div>
                    <div class="col">
                      <p style="margin-bottom: 0.3rem">&nbsp;</p>
                      <button type="button" class="btn btn-primary" @click="searchFilter" :disabled="isSearching">
                        <i class="fas fa-search"></i>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div class="
                    col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12
                    text-right
                  ">
                  <p style="margin-bottom: 0rem">&nbsp;</p>
                  <button type="button" class="btn btn-primary btn-sm-block mt-1" :disabled="isSearching" @click="
                    isEdit = false;
                  territoryModalShow = !territoryModalShow;
                  alias = [];
                  emailList = [];
                  $store.dispatch('territoryController/ResetTerritory');
                  ">
                    <i class="fas fa-user-plus"></i>
                    Add Agent
                  </button>
                  <!-- <button
                    type="button"
                    :disabled="isSearching"
                    class="
                      btn btn-primary btn-sm-block
                      mt-1
                      ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2
                    "
                    @click="
                      isEdit = false;
                      uploadTerritoryModalShow = !uploadTerritoryModalShow;
                      $store.dispatch('territoryController/ResetTerritory');
                    "
                  >
                    <i class="fas fa-upload"></i>
                    Upload Territory
                  </button> -->
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Agent Code</th>
                    <th>Agent Name</th>
                    <th>Territory Code</th>
                    <th>Territory Name</th>
                    <th>District</th>
                    <th>Aliases</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="territoriesPageItems.length == 0 && !isSearching">
                    <td colspan="10">
                      <div>
                        <lottie-animation style="height: 500px" :loop="true" :animationData="require('@/assets/lottie/no-data.json')
                          " />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="isSearching">
                    <td colspan="10">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(territory, i) in territoriesPageItems" :key="i">
                    <td>{{ territory.agentCode }}</td>
                    <td>{{ territory.agentName }}</td>
                    <td>{{ territory.territoryCode }}</td>
                    <td>{{ territory.territoryName }}</td>
                    <td>{{ territory.district }}</td>
                    <td>
                      <span class="agent-alias" v-for="(alias, e) in territory.aliases" :key="e">{{ alias }}</span>
                    </td>
                    <td>

                      <a href="#" @click="showUserAccess(territory.agentCode)">
                        Users
                      </a>
                      |
                      <a href="javascript:void(0)" @click="
                        editTerritory(territory);
                      isEdit = true;
                      ">
                        Edit
                      </a>
                      |
                      <a href="javascript:void(0)" @click="deleteTerritory(territory)">Delete</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination v-if="territoriesPageItems.length != 0" v-model="currentPage" :total-rows="rows"
              :per-page="pageSize" aria-controls="my-table" @change="paginationChanged"
              style="float: right; margin-top: 20px"></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="territoryModalShow" size="xl" title="Agent Details" hide-footer no-close-on-backdrop
      hide-header-close>
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtAgentCode">Agent Code</label>
              <input type="text" class="form-control" id="txtAgentCode" placeholder="Agent Code" :class="{
                'is-invalid': $v.territory.agentCode.$error,
              }" v-model="$v.territory.agentCode.$model" :disabled="isEdit" />
              <div class="form-error" v-if="$v.territory.agentCode.$error">
                Agent Code is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtAgentName">Agent Name</label>
              <input type="text" class="form-control" id="txtAgentName" placeholder="Agent Name" :class="{
                'is-invalid': $v.territory.agentName.$error,
              }" v-model="$v.territory.agentName.$model" />
              <div class="form-error" v-if="$v.territory.agentName.$error">
                Agent Name is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtTerritoryCode" ref="modalTerritoryDetails">
                Territory Code
              </label>
              <input type="text" class="form-control" id="txtTerritoryCode" placeholder="Territory Code" :class="{
                'is-invalid': $v.territory.territoryCode.$error,
              }" v-model="$v.territory.territoryCode.$model" />
              <div class="form-error" v-if="$v.territory.territoryCode.$error">
                Territory Code is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtTerritoryName">Territory Name</label>
              <input type="text" class="form-control" id="txtTerritoryName" placeholder="Territory Name" :class="{
                'is-invalid': $v.territory.territoryName.$error,
              }" v-model="$v.territory.territoryName.$model" />
              <div class="form-error" v-if="$v.territory.territoryName.$error">
                Territory Name is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtlistEmailAgent">Email for Agents</label>
              <multiselect v-model="emailList" tag-placeholder="Add this email" placeholder="Add Email for Agents"
                label="name" track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addAgentEmailTag"
                @remove="removeAgentEmailTag" :class="{
                  'is-invalid': $v.territory.emailList.$error,
                }"></multiselect>
              <div class="form-error" v-if="$v.territory.emailList.$error">
                Email for Agent is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtDMCode" ref="modalDMDetails">
                DM Code
              </label>
              <multiselect v-model="territory.selectedDistrict" label="name" track-by="name"
                :options="districtManagerOptions" @select="populateDistrict"></multiselect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtDMName">DM Name</label>
              <input type="text" class="form-control" id="txtDMName" placeholder="DM Name" v-model="territory.dmName"
                disabled />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtDistrict">District</label>
              <input type="text" class="form-control" id="txtDistrict" placeholder="District" :class="{
                'is-invalid': $v.territory.district.$error,
              }" v-model="$v.territory.district.$model" />
              <div class="form-error" v-if="$v.territory.district.$error">
                District is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtAliases">Aliases</label>
              <multiselect v-model="alias" tag-placeholder="Add this alias" placeholder="Add alias" label="name"
                track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addAliasTag"
                @remove="removeTag" :class="{
                  'is-invalid': $v.territory.aliases.$error,
                }"></multiselect>
              <div class="form-error" v-if="$v.territory.aliases.$error">
                Aliases is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="submitTerritory">
              Submit
            </button>
            <button type="button" class="btn btn-outline-light" @click="closeModal">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-sidebar id="sidebar-backdrop" backdrop shadow right width="1000px" no-header-close v-model="showSidebar"
      @change="changeSidebarStatus">
      <div class="px-3 py-3">
        <div class="row">
          <div class="col">
            <h3><strong>Agent User Access</strong></h3>
          </div>
          <div class="col text-right">
            <button type="button" class="btn btn-primary mr-2" @click="addAgentAccessModalShow = true; isEdit = false">
              Add User
            </button>
          </div>
        </div>

        <hr style="opacity: 0.08" />
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Report Access</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(agent, i) in lstAgentAccess" :key="i">
              <td>{{ agent.name }}</td>
              <td>{{ agent.email }}</td>
              <td>
                <p class="badge bg-primary mr-1" v-for="(access, x) in agent.listReportAccess" :key="x"
                  style="display: block; margin-bottom: 2px !important;">
                  {{ reportAccessOption.filter(n => n.code == access)[0]?.name }} <br />
                </p>
              </td>
              <td>
                {{
                  new Date(agent.dateCreated).toLocaleDateString('en-US', {
                    month: 'short',
                    day: "numeric",
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                }}
              </td>
              <td>
                <a href="#" @click="editAgentAccess(agent);">
                  Edit
                </a>
                |
                <a href="#" @click="
                  resetAgentAccess(agent.email);
                isEdit = true;
                ">
                  Reset
                </a>
                |
                <a href="#" @click="deleteAgentAccess(agent.agentUserAccessID)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>

    <!-- add agent access modal -->
    <b-modal v-model="addAgentAccessModalShow" size="md" title="Add Agent Access" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <p>
              Agent Code:
              <strong>{{ agentAccess.agentCode }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Name</label>
              <input class="form-control" type="text" v-model="agentAccess.name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Email</label>
              <input class="form-control" type="text" v-model="agentAccess.email" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Report Access</label>
              <multiselect v-model="listReportAccess" label="name" track-by="code" :options="reportAccessOption"
                :multiple="true" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="AddAgentAccess">
              Submit
            </button>
            <button type="button" class="btn btn-outline-light" @click="
              addAgentAccessModalShow = !addAgentAccessModalShow
              ">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      addAgentAccessModalShow: false,
      showSidebar: false,
      file: null,
      listEmailAgent: [],
      uploadTerritoryModalShow: false,
      territoriesPageItems: [],
      options: [],
      territoryModalShow: false,
      pageSize: 50,
      currentPage: 1,
      isSearching: false,
      isEdit: false,
      emailList: [],
      alias: [],
      selectedDistrict: {},
      listReportAccess: [
        { code: "DMR", name: "Delivery Monitoring Report" },
        { code: "SSR", name: "Sales Summary Report" },
        { code: "ILR", name: "Invoice Listing Report" },
        { code: "SRR", name: "Sales Returns Report" },
        { code: "FFSOHR", name: "Field Force Stock On Hand Report" },
        { code: "DAR", name: "Detailed Aging Report" },
        { code: "PCR", name: "PDC Collection Report" }
      ],
      reportAccessOption: [
        { code: "DMR", name: "Delivery Monitoring Report" },
        { code: "SSR", name: "Sales Summary Report" },
        { code: "ILR", name: "Invoice Listing Report" },
        { code: "SRR", name: "Sales Returns Report" },
        { code: "FFSOHR", name: "Field Force Stock On Hand Report" },
        { code: "DAR", name: "Detailed Aging Report" },
        { code: "PCR", name: "PDC Collection Report" }
      ],
    };
  },
  validations: {
    territory: {
      agentCode: {
        required,
      },
      territoryCode: {
        required,
      },
      territoryName: {
        required,
      },
      agentName: {
        required,
      },
      district: {
        required,
      },
      emailList: {
        required,
      },
      aliases: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      districts: (state) => state.districtController.districts,
      territories: (state) => state.territoryController.territories,
      territory: (state) => state.territoryController.territory,
      filter: (state) => state.territoryController.filters,
      lstAgentAccess: (state) => state.territoryController.lstAgentAccess,
      agentAccess: (state) => state.territoryController.agentAccess,
    }),
    rows() {
      return this.territories.length;
    },
    districtManagerOptions() {
      return this.districts?.map(function (a) {
        return {
          name: a.dmCode,
        };
      });
    },
  },
  methods: {
    editAgentAccess: function (agent) {
      this.agentAccess.agentUserAccessID = agent.agentUserAccessID;
      this.agentAccess.agentCode = agent.agentCode;
      this.agentAccess.name = agent.name;
      this.agentAccess.email = agent.email;
      this.listReportAccess = [];

      agent.listReportAccess.forEach(access => {
        this.listReportAccess.push(this.reportAccessOption.filter(m => m.code == access)[0]);
      });

      this.isEdit = true;
      this.addAgentAccessModalShow = true;
    },
    showUserAccess: async function (agentCode) {
      await this.$store.dispatch("showOverlay", true);
      var result = await this.$store
        .dispatch("territoryController/GetAgentAccess", agentCode)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data,
            type: "error",
          });
        });

      if (result) {
        await this.$store.dispatch("showOverlay", false);
        this.agentAccess.agentCode = agentCode;
        this.showSidebar = true;
      }
    },
    changeSidebarStatus: function (value) {
      this.showSidebar = value;
    },
    AddAgentAccess: async function () {
      await this.$store.dispatch("showOverlay", true);
      this.agentAccess.reportAccess = this.listReportAccess.map(m => m.code).join(';');
      var result = false;

      if (this.isEdit) {
        result = await this.$store
          .dispatch("territoryController/EditAgentAccess")
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });
      } else {
        result = await this.$store
          .dispatch("territoryController/AddAgentAccess")
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });
      }

      if (result) {
        await this.$store.dispatch("showOverlay", false);
        this.addAgentAccessModalShow = false;
        this.listReportAccess = [];

        var msg = this.isEdit ? 'updated' : 'added'
        this.$toast.open({
          message: `Agent user access has been successfully ${msg}.`,
          type: "success",
        });
      }
    },

    deleteAgentAccess: async function (id) {
      if (confirm("Are you sure you want to delete this agent access?")) {
        await this.$store.dispatch("showOverlay", true);
        var result = await this.$store
          .dispatch("territoryController/DeleteAgentAccess", id)
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });

        if (result) {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: "Agent user access has been successfully deleted.",
            type: "success",
          });
        }
      }
    },
    resetAgentAccess: async function (email) {
      if (confirm("Are you sure you want to reset password?")) {
        await this.$store.dispatch("showOverlay", true);
        var result = await this.$store
          .dispatch("territoryController/ResetAgentAccess", email)
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });

        if (result) {
          await this.$store.dispatch("showOverlay", false);
          this.addAgentAccessModalShow = false;
          this.$toast.open({
            message: "Agent user access has been successfully reset.",
            type: "success",
          });
        }
      }
    },
    addAgentEmailTag: function (email) {
      const tag = {
        name: email,
        code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };

      if (this.validateEmail(email)) {
        this.options.push(tag);
        this.emailList.push(tag);
      } else {
        alert("Email format is not valid");
      }
    },
    validateEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    addAliasTag: function (alias) {
      const tag = {
        name: alias,
        code: alias.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };

      this.options.push(tag);
      this.alias.push(tag);
    },
    updateAlias: function (removedOption) { },
    updateEmail: function (removedOption) { },
    removeAgentEmailTag: function (removedOption) {
      for (var i = 0; i < this.territory.emailList.length; i++) {
        var obj = this.territory.emailList[i];

        if (obj == removedOption.name) {
          this.territory.emailList.splice(i, 1);
        }
      }
    },
    removeTag: function (removedOption) {
      for (var i = 0; i < this.territory.aliases.length; i++) {
        var obj = this.territory.aliases[i];

        if (obj == removedOption.name) {
          this.territory.aliases.splice(i, 1);
        }
      }
    },
    paginationChanged: function (page) {
      if (page == null) {
        page = 1;
      }

      this.territoriesPageItems = this.territories.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    },
    populateDistrict: function (selected, id) {
      var dm = this.districts.filter(
        (m) => m.dmCode == selected.name
      )[0];

      this.territory.dmCode = dm.dmCode;
      this.territory.dmName = dm.dmName;
    },
    validateEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.territoriesPageItems = [];
      this.isSearching = true;
      var result = await this.$store
        .dispatch("territoryController/GetTerritories")
        .catch((error) => {
          this.isSearching = false;
        });

      if (result) {
        this.paginationChanged(this.currentPage);
        this.isSearching = false;
      }
    },
    submitTerritory: async function () {
      this.$v.territory.$touch();
      console.log(this.territory);
      this.territory.emailList = this.emailList.reduce(
        (a, o) => (o.name && a.push(o.name), a),
        []
      );

      this.territory.aliases = this.alias.reduce(
        (a, o) => (o.name && a.push(o.name), a),
        []
      );
      if (!this.$v.territory.$invalid) {
        if (this.isEdit) {
          await this.$store.dispatch("showOverlay", true);
          // edit existing record
          var result = await this.$store
            .dispatch("territoryController/EditTerritory")
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });

          if (result) {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: "Territory has been successfully edited.",
              type: "success",
            });

            this.paginationChanged(this.currentPage);
            this.territoryModalShow = false;
            this.listEmailAgent = [];
            this.$v.territory.$reset();
          }
        } else {
          await this.$store.dispatch("showOverlay", true);
          // insert new record
          var result = await this.$store
            .dispatch("territoryController/AddTerritory")
            .catch(async (error) => {
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });

          if (result) {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: "Territory has been successfully added.",
              type: "success",
            });

            this.paginationChanged(this.currentPage);
            this.territoryModalShow = false;
            this.listEmailAgent = [];
            this.$v.territory.$reset();
          }
        }
      }
    },
    editTerritory: function (territory) {
      this.emailList = [];
      this.alias = [];
      this.$store.dispatch("territoryController/BindTerritory", territory);

      territory.emailList.forEach((email) => {
        const tag = {
          name: email,
          code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
        };

        this.emailList.push(tag);
      });

      territory.aliases.forEach((aliasName) => {
        const tag = {
          name: aliasName,
          code:
            aliasName.substring(0, 2) + Math.floor(Math.random() * 10000000),
        };

        this.alias.push(tag);
      });

      this.territoryModalShow = !this.territoryModalShow;
    },
    deleteTerritory: function (territory) {
      var self = this;
      self.$store.dispatch("territoryController/BindTerritory", territory);

      self.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete " + territory.agentCode + "?",
          {
            title: "Delete Territory",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            var result = await self.$store.dispatch(
              "territoryController/DeleteTerritory"
            );

            if (result) {
              self.$toast.open({
                message:
                  "Territory " +
                  territory.agentCode +
                  " has been successfully deleted.",
                type: "success",
              });

              self.paginationChanged(self.currentPage);
            }
          }
        })
        .catch((err) => { });
    },
    closeModal: async function () {
      await this.searchFilter();
      this.territoryModalShow = !this.territoryModalShow;
      this.$v.$reset();
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style></style>