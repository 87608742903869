<template>
  <div class="nav-container">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="/dashboard">
            <i class="ti-home menu-icon"></i>
            <span class="menu-title">Dashboard</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/principal">
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">Principal Maintenance</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/customer">
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">Customer Maintenance</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/sat">
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">SAT Maintenance</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/district">
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">DM Maintenance</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/nationalsales">
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">NSM Maintenance</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/territory">
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">Agent Maintenance</span>
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-toggle="collapse"
            href="#reports"
            aria-expanded="false"
            aria-controls="reports"
          >
            <i class="ti-download menu-icon"></i>
            <span class="menu-title">Reports</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="reports">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <a class="nav-link" href="/reports/bincards">
                  BIN Card for Principal
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/reports/sales">
                  Sales Transaction
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/reports/deliverymonitoring">
                  Delivery Monitoring
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/reports/billcollector">
                  Detailed Aging
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/reports/pdccollection">
                  PDC Collection
                </a>
              </li>
              <li class="nav-item" @click="showGenerateAllReport = true">
                <a class="nav-link" href="#"> Generate All Reports </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/reports/logs"> Report Logs </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>

    <generate-all-report-modal
      :show="showGenerateAllReport"
      @on-close="onClose"
    />
  </div>
</template>

<script>
import generateAllReportModal from "@/views/reports/components/generateAllReport.vue";

export default {
  components: {
    generateAllReportModal,
  },
  data() {
    return {
      showGenerateAllReport: false,
    };
  },
  methods: {
    notImplemented: function () {
      this.$toast.open({
        message: "This feature is not yet implemented.",
        type: "info",
      });
    },
    onClose: function () {
      this.showGenerateAllReport = false;
    },
  },
};
</script>

<style>
</style>