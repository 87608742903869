<template>
  <div class="principal-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Principal Maintenance</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <label>Principal Code:</label>
                      <input type="text" class="form-control" v-model="filter.principalCode" placeholder="Principal Code"
                        :disabled="isSearching" />
                    </div>
                    <div class="col">
                      <p style="margin-bottom: 0.3rem">&nbsp;</p>
                      <button type="button" class="btn btn-primary" @click="searchFilter" :disabled="isSearching">
                        <i class="fas fa-search"></i>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div class="
                    col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12
                    text-right
                  ">
                  <p style="margin-bottom: 0rem">&nbsp;</p>
                  <button type="button" class="btn btn-primary btn-sm-block mt-1" :disabled="isSearching" @click="
                    isEdit = false;
                  principalModalShow = !principalModalShow;
                  listEmailStockOnHand = [];
                  listEmailDeliveryMonitoring = [];
                  listEmailInvoiceListing = [];
                  listEmailDetailedAging = [];
                  $store.dispatch('principalController/ResetPrincipal');
                  ">
                    <i class="fas fa-user-plus"></i>
                    Add Principal
                  </button>
                  <button type="button" :disabled="isSearching" class="
                      btn btn-primary btn-sm-block
                      mt-1
                      ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2
                    " @click="
                      isEdit = false;
                    uploadPrincipalModalShow = !uploadPrincipalModalShow;
                    $store.dispatch('principalController/ResetPrincipal');
                    ">
                    <i class="fas fa-upload"></i>
                    Upload Principal
                  </button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Principal Code</th>
                    <th>Principal Name</th>
                    <th>Email (Invoice Listing)</th>
                    <th>Email (Stock On Hand)</th>
                    <th>Email (Delivery Monitoring)</th>
                    <th>Email (Detailed Aging)</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="isSearching">
                    <td colspan="7">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="principalsPageItems.length == 0 && !isSearching">
                    <td colspan="7">
                      <div>
                        <lottie-animation style="height: 500px" :loop="true" :animationData="require('@/assets/lottie/no-data.json')
                          " />
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(principal, i) in principalsPageItems" :key="i">
                    <td>{{ principal.principalCode }}</td>
                    <td>{{ principal.principalName }}</td>
                    <td>
                      {{ principal.listEmailInvoiceListing.join(";") }}
                    </td>
                    <td>
                      {{ principal.listEmailStockOnHand.join(";") }}
                    </td>
                    <td>
                      {{ principal.listEmailDeliveryMonitoring.join(";") }}
                    </td>
                    <td>
                      {{ principal.listEmailDetailedAging.join(";") }}
                    </td>
                    <td>{{ principal.isActive ? "Active" : "Inactive" }}</td>
                    <td>
                      <a href="#" @click="showUserAccess(principal.principalCode)">
                        Users
                      </a>
                      |
                      <a href="#" @click="
                        editPrincipal(principal);
                      isEdit = true;
                      ">
                        Edit
                      </a>
                      |
                      <a href="#" @click="deletePrincipal(principal)">Delete</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination v-if="principalsPageItems.length != 0" v-model="currentPage" :total-rows="rows"
              :per-page="pageSize" aria-controls="my-table" @change="paginationChanged"
              style="float: right; margin-top: 20px"></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="principalModalShow" size="xl" title="Principal Details" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtPrincipalCode">Principal Code</label>
              <input type="text" class="form-control" id="txtPrincipalCode" placeholder="Principal Code" :class="{
                'is-invalid': $v.principal.principalCode.$error,
              }" v-model="$v.principal.principalCode.$model" :disabled="isEdit" />
              <div class="form-error" v-if="$v.principal.principalCode.$error">
                Principal Code is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtPrincipalName">Principal Name</label>
              <input type="text" class="form-control" id="txtPrincipalName" placeholder="Principal Name" :class="{
                'is-invalid': $v.principal.principalName.$error,
              }" v-model="$v.principal.principalName.$model" />
              <div class="form-error" v-if="$v.principal.principalName.$error">
                Principal Name is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtClassification">Classification</label>
              <input type="text" class="form-control" id="txtClassification" placeholder="Classification" :class="{
                'is-invalid': $v.principal.classification.$error,
              }" v-model="$v.principal.classification.$model" />
              <div class="form-error" v-if="$v.principal.classification.$error">
                Classification is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtTIN">TIN</label>
              <input type="text" class="form-control" id="txtTIN" placeholder="TIN" :class="{
                'is-invalid': $v.principal.tin.$error,
              }" v-model="$v.principal.tin.$model" />
              <div class="form-error" v-if="$v.principal.tin.$error">
                TIN is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtAddress">Address</label>
              <input type="text" class="form-control" id="txtAddress" placeholder="Address" :class="{
                'is-invalid': $v.principal.address.$error,
              }" v-model="$v.principal.address.$model" />
              <div class="form-error" v-if="$v.principal.address.$error">
                Address is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtTelephone">Telephone</label>
              <input type="text" class="form-control" id="txtTelephone" placeholder="Telephone" :class="{
                'is-invalid': $v.principal.telephone.$error,
              }" v-model="$v.principal.telephone.$model" />
              <div class="form-error" v-if="$v.principal.telephone.$error">
                Telephone is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtMobileNumber">Mobile Number</label>
              <input type="text" class="form-control" id="txtMobileNumber" placeholder="Mobile Number" :class="{
                'is-invalid': $v.principal.mobileNumber.$error,
              }" v-model="$v.principal.mobileNumber.$model" />
              <div class="form-error" v-if="$v.principal.mobileNumber.$error">
                Mobile Number is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtListEmailInvoiceListing">Email for Invoice Listing</label>
              <multiselect v-model="listEmailInvoiceListing" tag-placeholder="Add this email"
                placeholder="Add Email for Invoice Listing" label="name" track-by="code" :options="options"
                :multiple="true" :taggable="true" @tag="addInvoiceEmailTag" :class="{
                  'is-invalid': $v.listEmailInvoiceListing.$error,
                }"></multiselect>
              <div class="form-error" v-if="$v.listEmailInvoiceListing.$error">
                Email for Invoice Listing is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtListEmailStockOnHand">Email for Stock On Hand</label>
              <multiselect v-model="listEmailStockOnHand" tag-placeholder="Add this email"
                placeholder="Add Email for Stock On Hand" label="name" track-by="code" :options="options" :multiple="true"
                :taggable="true" @tag="addStockOnHandEmailTag" :class="{
                  'is-invalid': $v.listEmailStockOnHand.$error,
                }"></multiselect>
              <div class="form-error" v-if="$v.listEmailStockOnHand.$error">
                Email for Stock On Hand is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtListEmailDeliveryMonitoring">Email for Delivery Monitoring</label>
              <multiselect v-model="listEmailDeliveryMonitoring" tag-placeholder="Add this email"
                placeholder="Add Email for Delivery Monitoring" label="name" track-by="code" :options="options"
                :multiple="true" :taggable="true" @tag="addDeliveryMonitoringEmailTag" :class="{
                  'is-invalid': $v.listEmailDeliveryMonitoring.$error,
                }"></multiselect>
              <div class="form-error" v-if="$v.listEmailDeliveryMonitoring.$error">
                Email for Delivery Monitoring is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtListEmailDetailedAging">Email for Detailed Aging</label>
              <multiselect v-model="listEmailDetailedAging" tag-placeholder="Add this email"
                placeholder="Add Email for Detailed Aging" label="name" track-by="code" :options="options"
                :multiple="true" :taggable="true" @tag="addDetailedAgingEmailTag" :class="{
                  'is-invalid': $v.listEmailDetailedAging.$error,
                }"></multiselect>
              <div class="form-error" v-if="$v.listEmailDetailedAging.$error">
                Email for Detailed Aging is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtWebsite">Website</label>
              <input type="text" class="form-control" id="txtWebsite" placeholder="Website" v-model="principal.website" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtGroupCode">Group Code</label>
              <input type="text" class="form-control" id="txtGroupCode" placeholder="Group Code" :class="{
                'is-invalid': $v.principal.groupCode.$error,
              }" v-model="$v.principal.groupCode.$model" />
              <div class="form-error" v-if="$v.principal.groupCode.$error">
                Group Code is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check form-check-info">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" checked="" v-model="principal.isActive" />
                Active
                <i class="input-helper"></i>
              </label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="submitPrincipal">
              Submit
            </button>
            <button type="button" class="btn btn-outline-light" @click="
              principalModalShow = !principalModalShow;
            $v.$reset();
            ">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- upload modal -->
    <b-modal v-model="uploadPrincipalModalShow" size="md" title="Upload Principal" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Principal File</label>
              <input class="form-control" type="file" v-on:change="handleFileUpload($event)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="uploadFile">
              Upload
            </button>
            <button type="button" class="btn btn-outline-light"
              @click="uploadPrincipalModalShow = !uploadPrincipalModalShow">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-sidebar id="sidebar-backdrop" backdrop shadow right width="1000px" no-header-close v-model="showSidebar"
      @change="changeSidebarStatus">
      <div class="px-3 py-3">
        <div class="row">
          <div class="col">
            <h3><strong>Principal User Access</strong></h3>
          </div>
          <div class="col text-right">
            <button type="button" class="btn btn-primary mr-2"
              @click="addPrincipalAccessModalShow = true; isEdit = false;">
              Add User
            </button>
          </div>
        </div>

        <hr style="opacity: 0.08" />
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Report Access</th>
              <th>Date Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(principal, i) in lstPrincipalAccess" :key="i">
              <td>{{ principal.name }}</td>
              <td>{{ principal.email }}</td>
              <td>
                <p class="badge bg-primary mr-1" v-for="(access, x) in principal.listReportAccess" :key="x"
                  style="display: block; margin-bottom: 2px !important;">
                  {{ reportAccessOption.filter(n => n.code == access)[0]?.name }} <br />
                </p>
              </td>
              <td>
                {{
                  new Date(principal.dateCreated).toLocaleDateString('en-US', {
                    month: 'short',
                    day: "numeric",
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                }}
              </td>
              <td>
                <a href="#" @click="editPrincipalAccess(principal);">
                  Edit
                </a>
                |
                <a href="#" @click="
                  resetPrincipalAccess(principal.email);
                ">
                  Reset
                </a>
                |
                <a href="#" @click="deletePrincipalAccess(principal.email)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-sidebar>

    <!-- add principal access modal -->
    <b-modal v-model="addPrincipalAccessModalShow" size="md" title="Add Principal Access" hide-footer
      no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <p>
              Principal Code:
              <strong>{{ principalAccess.principalCode }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Name</label>
              <input class="form-control" type="text" v-model="principalAccess.name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Email</label>
              <input class="form-control" type="text" v-model="principalAccess.email" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtFirstName">Report Access</label>
              <multiselect v-model="listReportAccess" label="name" track-by="code" :options="reportAccessOption"
                :multiple="true" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="AddPrincipalAccess">
              Submit
            </button>
            <button type="button" class="btn btn-outline-light" @click="
              addPrincipalAccessModalShow = !addPrincipalAccessModalShow
              ">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      togglePassword: false,
      showSidebar: false,
      file: null,
      addPrincipalAccessModalShow: false,
      uploadPrincipalModalShow: false,
      principalsPageItems: [],
      listEmailInvoiceListing: [],
      listEmailStockOnHand: [],
      listEmailDeliveryMonitoring: [],
      listEmailDetailedAging: [],
      options: [],
      principalModalShow: false,
      pageSize: 50,
      currentPage: 1,
      isSearching: false,
      isEdit: false,
      reportAccessOption: [
        { code: "DMR", name: "Delivery Monitoring Report" },
        { code: "SSR", name: "Sales Summary Report" },
        { code: "ILR", name: "Invoice Listing Report" },
        { code: "SRR", name: "Sales Returns Report" },
        { code: "SOHR", name: "Stock On Hand Report" },
        // { code: "DAR", name: "Detailed Aging Report" },
        // { code: "PCR", name: "PDC Collection Report" }
      ],
      listReportAccess: []
    };
  },
  validations: {
    file: {
      required,
    },
    listEmailInvoiceListing: {
      required,
    },
    listEmailStockOnHand: {
      required,
    },
    listEmailDeliveryMonitoring: {
      required,
    },
    listEmailDetailedAging: {
      required,
    },
    principal: {
      principalCode: {
        required,
      },
      principalName: {
        required,
      },
      classification: {
        required,
      },
      address: {
        required,
      },
      telephone: {
        required,
      },
      mobileNumber: {
        required,
      },
      tin: {
        required,
      },
      groupCode: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      principals: (state) => state.principalController.principals,
      lstPrincipalAccess: (state) =>
        state.principalController.lstPrincipalAccess,
      principalAccess: (state) => state.principalController.principalAccess,
      principal: (state) => state.principalController.principal,
      filter: (state) => state.principalController.filters,
    }),
    rows() {
      return this.principals.length;
    },
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      await this.$store.dispatch("showOverlay", true);
      var result = await this.$store
        .dispatch("principalController/UploadPrincipal", this.file)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data.Message,
            type: "error",
          });
        });

      if (result) {
        await this.searchFilter();
        await this.$store.dispatch("showOverlay", false);
        this.uploadPrincipalModalShow = false;
        this.$toast.open({
          message: "Principal batch file has been successfully uploaded.",
          type: "success",
        });
      }
    },
    paginationChanged: function (page) {
      if (page == null) {
        page = 1;
      }

      this.principalsPageItems = this.principals.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    },
    validateEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    addDeliveryMonitoringEmailTag: function (email) {
      const tag = {
        name: email,
        code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };

      if (this.validateEmail(email)) {
        this.options.push(tag);
        this.listEmailDeliveryMonitoring.push(tag);
      } else {
        alert("Email format is not valid");
      }
    },
    addDetailedAgingEmailTag: function (email) {
      const tag = {
        name: email,
        code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };

      if (this.validateEmail(email)) {
        this.options.push(tag);
        this.listEmailDetailedAging.push(tag);
      } else {
        alert("Email format is not valid");
      }
    },
    addInvoiceEmailTag: function (email) {
      const tag = {
        name: email,
        code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };

      if (this.validateEmail(email)) {
        this.options.push(tag);
        this.listEmailInvoiceListing.push(tag);
      } else {
        alert("Email format is not valid");
      }
    },
    addStockOnHandEmailTag: function (email) {
      const tag = {
        name: email,
        code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };

      if (this.validateEmail(email)) {
        this.options.push(tag);
        this.listEmailStockOnHand.push(tag);
      } else {
        alert("Email format is not valid");
      }
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.principalsPageItems = [];
      this.isSearching = true;
      var result = await this.$store
        .dispatch("principalController/GetPrincipals")
        .catch((error) => {
          this.isSearching = false;
        });

      if (result) {
        this.paginationChanged(this.currentPage);
        this.isSearching = false;
      }
    },
    submitPrincipal: async function () {
      this.principal.listEmailInvoiceListing =
        this.listEmailInvoiceListing.reduce(
          (a, o) => (o.name && a.push(o.name), a),
          []
        );
      this.principal.listEmailStockOnHand = this.listEmailStockOnHand.reduce(
        (a, o) => (o.name && a.push(o.name), a),
        []
      );
      this.principal.listEmailDeliveryMonitoring =
        this.listEmailDeliveryMonitoring.reduce(
          (a, o) => (o.name && a.push(o.name), a),
          []
        );
      this.principal.listEmailDetailedAging =
        this.listEmailDetailedAging.reduce(
          (a, o) => (o.name && a.push(o.name), a),
          []
        );

      // this.$v.principal.$touch();
      // this.$v.listEmailInvoiceListing.$touch();
      // this.$v.listEmailStockOnHand.$touch();
      // this.$v.listEmailDeliveryMonitoring.$touch();

      // if (
      //   !this.$v.principal.$invalid &&
      //   !this.$v.listEmailInvoiceListing.$invalid &&
      //   !this.$v.listEmailStockOnHand.$invalid &&
      //   !this.$v.listEmailDeliveryMonitoring.$invalid
      // ) {
      if (this.isEdit) {
        await this.$store.dispatch("showOverlay", true);
        // edit existing record
        var result = await this.$store
          .dispatch("principalController/EditPrincipal")
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data.Message,
              type: "error",
            });
          });

        if (result) {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: "Principal has been successfully edited.",
            type: "success",
          });

          this.paginationChanged(this.currentPage);

          this.principalModalShow = false;
          this.listEmailInvoiceListing = [];
          this.listEmailStockOnHand = [];
          this.listEmailDeliveryMonitoring = [];
          this.listEmailDetailedAging = [];

          this.$v.principal.$reset();
          this.$v.listEmailInvoiceListing.$reset();
          this.$v.listEmailStockOnHand.$reset();
          this.$v.listEmailDeliveryMonitoring.$reset();
          this.$v.listEmailDetailedAging.$reset();
        }
      } else {
        await this.$store.dispatch("showOverlay", true);
        // insert new record
        var result = await this.$store
          .dispatch("principalController/AddPrincipal")
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data.Message,
              type: "error",
            });
          });

        if (result) {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: "Principal has been successfully added.",
            type: "success",
          });

          this.paginationChanged(this.currentPage);

          this.principalModalShow = false;
          this.listEmailInvoiceListing = [];
          this.listEmailStockOnHand = [];
          this.listEmailDeliveryMonitoring = [];
          this.listEmailDetailedAging = [];

          this.$v.principal.$reset();
          this.$v.listEmailInvoiceListing.$reset();
          this.$v.listEmailStockOnHand.$reset();
          this.$v.listEmailDeliveryMonitoring.$reset();
          this.$v.listEmailDetailedAging.$reset();
        }
      }
      // }
    },
    editPrincipal: function (principal) {
      this.$store.dispatch("principalController/BindPrincipal", principal);
      this.listEmailInvoiceListing = [];
      this.listEmailStockOnHand = [];
      this.listEmailDeliveryMonitoring = [];
      this.listEmailDetailedAging = [];

      principal.listEmailInvoiceListing.forEach((email) => {
        const tag = {
          name: email,
          code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
        };

        this.listEmailInvoiceListing.push(tag);
      });

      principal.listEmailStockOnHand.forEach((email) => {
        const tag = {
          name: email,
          code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
        };

        this.listEmailStockOnHand.push(tag);
      });

      principal.listEmailDeliveryMonitoring.forEach((email) => {
        const tag = {
          name: email,
          code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
        };

        this.listEmailDeliveryMonitoring.push(tag);
      });

      principal.listEmailDetailedAging.forEach((email) => {
        const tag = {
          name: email,
          code: email.substring(0, 2) + Math.floor(Math.random() * 10000000),
        };

        this.listEmailDetailedAging.push(tag);
      });

      this.principalModalShow = !this.principalModalShow;
    },
    deletePrincipal: function (principal) {
      var self = this;
      self.$store.dispatch("principalController/BindPrincipal", principal);

      self.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete " + principal.principalCode + "?",
          {
            title: "Delete Principal",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            var result = await self.$store.dispatch(
              "principalController/DeletePrincipal",
              principal
            );

            if (result) {
              self.$toast.open({
                message:
                  "Principal " +
                  principal.principalCode +
                  " has been successfully deleted.",
                type: "success",
              });

              self.paginationChanged(self.currentPage);
            }
          }
        })
        .catch((err) => { });
    },
    editPrincipalAccess: function (principal) {
      this.principalAccess.principalCode = principal.principalCode;
      this.principalAccess.name = principal.name;
      this.principalAccess.email = principal.email;
      this.listReportAccess = [];

      principal.listReportAccess.forEach(access => {
        this.listReportAccess.push(this.reportAccessOption.filter(m => m.code == access)[0]);
      });

      this.isEdit = true;
      this.addPrincipalAccessModalShow = true;
    },
    showUserAccess: async function (principalCode) {
      await this.$store.dispatch("showOverlay", true);
      var result = await this.$store
        .dispatch("principalController/GetPrincipalAccess", principalCode)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data,
            type: "error",
          });
        });

      if (result) {
        await this.$store.dispatch("showOverlay", false);
        this.principalAccess.principalCode = principalCode;
        this.showSidebar = true;
      }
    },
    changeSidebarStatus: function (value) {
      this.showSidebar = value;
    },
    AddPrincipalAccess: async function () {
      await this.$store.dispatch("showOverlay", true);
      this.principalAccess.reportAccess = this.listReportAccess.map(m => m.code).join(';');
      var result = false;

      if (this.isEdit) {
        result = await this.$store
          .dispatch("principalController/EditPrincipalAccess")
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });
      } else {
        result = await this.$store
          .dispatch("principalController/AddPrincipalAccess")
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });
      }


      if (result) {
        await this.$store.dispatch("showOverlay", false);
        this.addPrincipalAccessModalShow = false;
        this.listReportAccess = [];

        var msg = this.isEdit ? 'updated' : 'added'
        this.$toast.open({
          message: `Agent user access has been successfully ${msg}.`,
          type: "success",
        });
      }
    },
    deletePrincipalAccess: async function (email) {
      if (confirm("Are you sure you want to delete this principal access?")) {
        await this.$store.dispatch("showOverlay", true);
        var result = await this.$store
          .dispatch("principalController/DeletePrincipalAccess", email)
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });

        if (result) {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: "Principal user access has been successfully deleted.",
            type: "success",
          });
        }
      }
    },
    resetPrincipalAccess: async function (email) {
      if (confirm("Are you sure you want to reset password?")) {
        await this.$store.dispatch("showOverlay", true);
        var result = await this.$store
          .dispatch("principalController/ResetPrincipalAccess", email)
          .catch(async (error) => {
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data,
              type: "error",
            });
          });

        if (result) {
          await this.$store.dispatch("showOverlay", false);
          this.addPrincipalAccessModalShow = false;
          this.$toast.open({
            message: "Principal user access has been successfully reset.",
            type: "success",
          });
        }
      }
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style></style>