<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth px-0">
      <div class="row w-100 mx-0">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left py-5 px-4 px-sm-5">
            <div class="row">
              <div class="col">
                <div>
                  <img
                    class="iconDetails"
                    src="../../assets/logo.png"
                    alt="logo"
                  />
                </div>
                <div style="margin-top: 70px; margin-left: 130px">
                  <h4>Hello! let's get started</h4>
                  <div style="font-size: 0.6em">Sign in to continue</div>
                </div>
              </div>
            </div>
            <form class="pt-3">
              <b-alert show variant="danger" v-if="hasLoginError">
                <b-icon
                  class="mr-1"
                  icon="exclamation-triangle"
                  variant="danger"
                ></b-icon>
                Incorrect email/username or password.
              </b-alert>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  :class="{ 'is-invalid': $v.user.email.$error }"
                  placeholder="Enter your email address"
                  v-model="$v.user.email.$model"
                  :disabled="isLoading"
                  @keyup.enter="validateCredentials"
                />
                <div class="form-error" v-if="$v.user.email.$error">
                  Email address must be in valid format.
                </div>
              </div>
              <div class="form-group">
                <input
                  type="password"
                  class="form-control form-control-lg"
                  placeholder="Enter your password"
                  v-model="$v.user.password.$model"
                  :class="{ 'is-invalid': $v.user.password.$error }"
                  :disabled="isLoading"
                  @keyup.enter="validateCredentials"
                />
                <div class="form-error" v-if="$v.user.password.$error">
                  Password is required.
                </div>
              </div>
              <div class="mt-3">
                <button
                  type="button"
                  :disabled="isLoading"
                  class="
                    btn btn-block btn-danger btn-lg
                    font-weight-medium
                    auth-form-btn
                  "
                  @click="validateCredentials"
                >
                  <i class="fas fa-spinner fa-spin mr-1" v-if="isLoading"></i>
                  <i
                    class="menu-item-icon icon ion-log-in mr-1"
                    v-if="!isLoading"
                  ></i>
                  {{ isLoading ? "Signing In" : "Sign In" }}
                </button>
              </div>
              <div
                class="
                  my-2
                  d-flex
                  justify-content-center
                  align-items-center
                  mt-3
                "
              >
                <a href="#" class="auth-link text-black">Forgot password?</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      isLoading: false,
      hasLoginError: false,
    };
  },
  methods: {
    validateCredentials: async function () {
      this.isLoading = true;
      this.hasLoginError = false;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        var result = await this.$store
          .dispatch("userController/Login")
          .catch((error) => {
            this.hasLoginError = true;
          });

        if (result) {
          window.location.href = "/dashboard";
        } else {
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.userController.user,
    }),
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
};
</script>

<style scoped>
.iconDetails {
  float: left;
}
h4 {
  margin: 0px;
}
</style>