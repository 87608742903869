<template>
  <div class="admin-container">
    <navbar />

    <div class="container-fluid page-body-wrapper">
      <sidebar />

      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper content-wrapper-admin">
          <div class="content-wrapper-body">
            <router-view />
          </div>
        </div>

        <footer class="footer">
          <div
            class="d-sm-flex justify-content-center justify-content-sm-between"
          >
            <span
              class="
                text-muted text-center text-sm-left
                d-block d-sm-inline-block
              "
              >Copyright &copy; 2021. Oxford Distributions Incorporated.</span
            >
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import navbar from "./components/navbar.vue";
import sidebar from "./components/sidebar.vue";

export default {
  components: {
    sidebar,
    navbar,
  },
};
</script>

<style>
</style>