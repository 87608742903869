<template>
  <div class="sales-monitoring-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Sales Transaction Reports</h4>
            <div class="filters">
              <div class="row mb-3">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <button type="button" class="
                          btn btn-primary btn-sm-block
                          mt-1
                          ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2
                        " @click="uploadModalShow = true">
                        <i class="fas fa-upload"></i>
                        Upload Sales Report Raw File
                      </button>
                      <button type="button" class="
                          btn btn-primary btn-sm-block
                          mt-1
                          ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2
                        " @click="showInvoiceValidatorModal = true">
                        <i class="fas fa-download"></i>
                        Generate Invoice Listing Validator
                      </button>
                    </div>
                  </div>
                </div>
                <div class="
                    col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12
                    text-right
                  ">
                  <button type="button" class="
                      btn btn-danger
                      dropdown-toggle
                      btn-sm-block
                      mt-1
                      ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2
                    " id="dropdownMenuSizeButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-file-export"></i>
                    Generate Report
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuSizeButton1">
                    <a class="dropdown-item" href="#" @click="invoiceListingReportModalShow = true">Sales Report</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" @click="salesSummaryReportModalShow = true">Sales Summary
                      Report</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" @click="salesReturnReportModalShow = true">Returns Report</a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <lottie-animation style="height: 500px" :loop="true"
                :animationData="require('@/assets/lottie/report.json')" />
            </div>
            <!-- <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Period</th>
                    <th>Principal Code</th>
                    <th>Principal Name</th>
                    <th>Customer Code</th>
                    <th>Customer Name</th>
                    <th>Bill To Address</th>
                    <th>Ship to Code</th>
                    <th>Address</th>
                    <th>Reference Number</th>
                    <th>Invoice Reference</th>
                    <th>SO Number</th>
                    <th>SO Date</th>
                    <th>PO Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="hasNoRecord">
                    <td colspan="14">
                      <div>
                        <lottie-animation
                          style="height: 500px"
                          :loop="true"
                          :animationData="
                            require('@/assets/lottie/no-data.json')
                          "
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="isSearching">
                    <td colspan="14">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(sales, i) in salesReportPageItems" :key="i">
                    <td>{{ sales.year }}</td>
                    <td>{{ sales.period }}</td>
                    <td>{{ sales.principalCode }}</td>
                    <td>{{ sales.principalName }}</td>
                    <td>{{ sales.customerCode }}</td>
                    <td>{{ sales.customerName }}</td>
                    <td>{{ sales.billToAddress }}</td>
                    <td>{{ sales.shipToCode }}</td>
                    <td>{{ sales.address }}</td>
                    <td>{{ sales.referenceNumber }}</td>
                    <td>{{ sales.invoiceReference }}</td>
                    <td>{{ sales.sONumber }}</td>
                    <td>{{ sales.sODate }}</td>
                    <td>{{ sales.pONumber }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-pagination
              v-if="salesReportPageItems.length != 0"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="pageSize"
              aria-controls="my-table"
              @change="paginationChanged"
              style="float: right; margin-top: 20px"
            ></b-pagination> -->
          </div>
        </div>
      </div>
    </div>

    <!-- upload modal -->
    <b-modal v-model="uploadModalShow" size="md" title="Sales Report" hide-footer no-close-on-backdrop>
      <form class="forms-sample">
        <!-- <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtYear">Year</label>
              <input
                type="text"
                class="form-control"
                id="txtYear"
                placeholder="Year"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="txtMonth">Month</label>
              <input
                type="text"
                class="form-control"
                id="txtMonth"
                placeholder="Month"
              />
            </div>
          </div>
        </div> -->
        <div class="row">
          <!-- <div class="col-2">
            <div class="form-group">
              <label>Version</label>
              <select class="form-control" v-model="version">
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
          </div> -->
          <div class="col">
            <div class="form-group">
              <label>Raw File</label>
              <input class="form-control" type="file" v-on:change="handleFileUpload($event)"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="uploadFile">
              Upload
            </button>
            <button type="button" class="btn btn-light" @click="uploadModalShow = !uploadModalShow">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- invoice listing validator modal -->
    <b-modal v-model="showInvoiceValidatorModal" size="md" title="Invoice Listing Validator" hide-footer
      no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtReportDateRange">Report Date Range</label>
              <date-picker v-model="invoiceListingReportFilter.dateRange" :clearable="false" format="MM/DD/YYYY"
                title-format="MM/DD/YYYY" time-title-format="MM/DD/YYYY" input-class="form-control"
                :input-attr="{ id: 'txtReportDateRange' }" :disabled="isGenerating" range></date-picker>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="generateInvoiceListingValidator"
              :disabled="isGenerating">
              <i class="fas fa-spinner fa-spin mr-1" v-if="isGenerating" />
              Generate
            </button>
            <button type="button" class="btn btn-light" @click="showInvoiceValidatorModal = !showInvoiceValidatorModal"
              :disabled="isGenerating">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- generate invoice listing report modal -->
    <b-modal v-model="invoiceListingReportModalShow" size="md" title="Invoice Listing Report" hide-footer
      no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="chkReportType">Report Type</label>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="principal" v-model="reportType"
                        :disabled="isGenerating" />
                      Principal Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="agent" v-model="reportType"
                        :disabled="isGenerating" />
                      Agent Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="district" v-model="reportType"
                        :disabled="isGenerating" />
                      DM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="nationalsales" v-model="reportType"
                        :disabled="isGenerating" />
                      NSM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'agent'">
          <div class="col">
            <div class="form-group">
              <label for="txtAgentCode">Agent Code</label>
              <multiselect id="txtAgentCode" v-model="value" placeholder="Search Agent Code" label="name"
                track-by="code" :options="agentOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'principal'">
          <div class="col">
            <div class="form-group">
              <label for="txtPrincipalCode">Principal Code</label>
              <multiselect id="txtPrincipalCode" v-model="value" placeholder="Search Principal Code" label="name"
                track-by="code" :options="principalOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'district'">
          <div class="col">
            <div class="form-group">
              <label for="txtDMCode">DM Code</label>
              <multiselect id="txtDMCode" v-model="value" placeholder="Search DM Code" label="name" track-by="code"
                :options="dmOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'nationalsales'">
          <div class="col">
            <div class="form-group">
              <label for="txtNSMCode">NSM Code</label>
              <multiselect id="txtNSMCode" v-model="value" placeholder="Search NSM Code" label="name" track-by="code"
                :options="nsmOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtReportDateRange">Report Date Range</label>
              <date-picker v-model="invoiceListingReportFilter.dateRange" :clearable="false" format="MM/DD/YYYY"
                title-format="MM/DD/YYYY" time-title-format="MM/DD/YYYY" input-class="form-control"
                :input-attr="{ id: 'txtReportDateRange' }" :disabled="isGenerating" range></date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check form-check-flat form-check-primary">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="invoiceListingReportFilter.isOfficial"
                  :disabled="isGenerating" />
                Is Official
                <i class="input-helper"></i></label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="generateInvoiceListingReport"
              :disabled="isGenerating">
              <i class="fas fa-spinner fa-spin mr-1" v-if="isGenerating" />
              Generate
            </button>
            <button type="button" class="btn btn-light" @click="
                          invoiceListingReportModalShow = !invoiceListingReportModalShow;
                        reportType = 'principal';
                        value = [];
                        " :disabled="isGenerating">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- generate sales summary report modal -->
    <b-modal v-model="salesSummaryReportModalShow" size="md" title="Sales Summary Report" hide-footer
      no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="chkReportType">Report Type</label>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="principal" v-model="reportType"
                        :disabled="isGenerating" />
                      Principal Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="agent" v-model="reportType"
                        :disabled="isGenerating" />
                      Agent Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="district" v-model="reportType"
                        :disabled="isGenerating" />
                      DM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="nationalsales" v-model="reportType"
                        :disabled="isGenerating" />
                      NSM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'agent'">
          <div class="col">
            <div class="form-group">
              <label for="txtAgentCode">Agent Code</label>
              <multiselect id="txtAgentCode" v-model="value" placeholder="Search Agent Code" label="name"
                track-by="code" :options="agentOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'principal'">
          <div class="col">
            <div class="form-group">
              <label for="txtPrincipalCode">Principal Code</label>
              <multiselect id="txtPrincipalCode" v-model="value" placeholder="Search Principal Code" label="name"
                track-by="code" :options="principalOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'district'">
          <div class="col">
            <div class="form-group">
              <label for="txtDMCode">DM Code</label>
              <multiselect id="txtDMCode" v-model="value" placeholder="Search DM Code" label="name" track-by="code"
                :options="dmOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'nationalsales'">
          <div class="col">
            <div class="form-group">
              <label for="txtNSMCode">NSM Code</label>
              <multiselect id="txtNSMCode" v-model="value" placeholder="Search NSM Code" label="name" track-by="code"
                :options="nsmOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtReportDateRange">Report Date Range</label>
              <date-picker v-model="salesSummaryReportFilter.dateRange" :clearable="false" format="MM/DD/YYYY"
                title-format="MM/DD/YYYY" time-title-format="MM/DD/YYYY" input-class="form-control"
                :input-attr="{ id: 'txtReportDateRange' }" :disabled="isGenerating" range></date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check form-check-flat form-check-primary">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="salesSummaryReportFilter.isOfficial"
                  :disabled="isGenerating" />
                Is Official
                <i class="input-helper"></i></label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="generateSalesSummaryReport"
              :disabled="isGenerating">
              <i class="fas fa-spinner fa-spin mr-1" v-if="isGenerating" />
              Generate
            </button>
            <button type="button" class="btn btn-light" @click="
                          salesSummaryReportModalShow = !salesSummaryReportModalShow;
                        reportType = 'principal';
                        value = [];
                        " :disabled="isGenerating">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- generate sales return report modal -->
    <b-modal v-model="salesReturnReportModalShow" size="md" title="Sales Return Report" hide-footer
      no-close-on-backdrop>
      <form class="forms-sample">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="chkReportType">Report Type</label>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="principal" v-model="reportType"
                        :disabled="isGenerating" />
                      Principal Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="agent" v-model="reportType"
                        :disabled="isGenerating" />
                      Agent Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="district" v-model="reportType"
                        :disabled="isGenerating" />
                      DM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="radio" class="form-check-input" value="nationalsales" v-model="reportType"
                        :disabled="isGenerating" />
                      NSM Report
                      <i class="input-helper"></i></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'agent'">
          <div class="col">
            <div class="form-group">
              <label for="txtAgentCode">Agent Code</label>
              <multiselect id="txtAgentCode" v-model="value" placeholder="Search Agent Code" label="name"
                track-by="code" :options="agentOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'principal'">
          <div class="col">
            <div class="form-group">
              <label for="txtPrincipalCode">Principal Code</label>
              <multiselect id="txtPrincipalCode" v-model="value" placeholder="Search Principal Code" label="name"
                track-by="code" :options="principalOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'district'">
          <div class="col">
            <div class="form-group">
              <label for="txtDMCode">DM Code</label>
              <multiselect id="txtDMCode" v-model="value" placeholder="Search DM Code" label="name" track-by="code"
                :options="dmOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row" v-if="reportType == 'nationalsales'">
          <div class="col">
            <div class="form-group">
              <label for="txtNSMCode">NSM Code</label>
              <multiselect id="txtNSMCode" v-model="value" placeholder="Search NSM Code" label="name" track-by="code"
                :options="nsmOptions" :multiple="true"></multiselect>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtReportDateRange">Report Date Range</label>
              <date-picker v-model="salesReturnReportFilter.dateRange" :clearable="false" format="MM/DD/YYYY"
                title-format="MM/DD/YYYY" time-title-format="MM/DD/YYYY" input-class="form-control"
                :input-attr="{ id: 'txtReportDateRange' }" :disabled="isGenerating" range></date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check form-check-flat form-check-primary">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="salesReturnReportFilter.isOfficial"
                  :disabled="isGenerating" />
                Is Official
                <i class="input-helper"></i></label>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button type="button" class="btn btn-primary mr-2" @click="generateSalesReturnReport"
              :disabled="isGenerating">
              <i class="fas fa-spinner fa-spin mr-1" v-if="isGenerating" />
              Generate
            </button>
            <button type="button" class="btn btn-light" @click="
                          salesReturnReportModalShow = !salesReturnReportModalShow;
                        reportType = 'principal';
                        value = [];
                        " :disabled="isGenerating">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LottieAnimation from "lottie-web-vue";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      value: [],
      reportType: "principal",
      salesReportPageItems: [],
      showInvoiceValidatorModal: false,
      invoiceListingReportModalShow: false,
      salesSummaryReportModalShow: false,
      salesReturnReportModalShow: false,
      uploadModalShow: false,
      pageSize: 15,
      currentPage: 1,
      pageOfItems: [],
      isSearching: false,
      hasNoRecord: false,
      emailError: "",
      passwordError: "",
      isEdit: false,
      isGenerating: false,
      file: null,
      version: 2,
    };
  },
  watch: {
    reportType: {
      handler() {
        this.value = [];
      },
      deep: true,
    },
  },
  validations: {
    invoiceListingReportFilter: {
      dateRange: {
        required,
      },
    },
    salesSummaryReportFilter: {
      dateRange: {
        required,
      },
    },
    salesReturnReportFilter: {
      dateRange: {
        required,
      },
    },
  },
  computed: {
    ...mapState({
      principals: (state) => state.principalController.principals,
      agents: (state) => state.territoryController.territories,
      districts: (state) => state.districtController.districts,
      nationalSales: (state) => state.nationalSalesController.nationalSales,
      salesReports: (state) => state.reportController.salesReports,
      invoiceListingReportFilter: (state) =>
        state.reportController.invoiceListingReportFilter,
      salesSummaryReportFilter: (state) =>
        state.reportController.salesSummaryReportFilter,
      salesReturnReportFilter: (state) =>
        state.reportController.salesReturnReportFilter,
    }),
    rows() {
      return this.salesReports.length;
    },
    agentOptions() {
      return this.agents.map((mg) => ({
        ["name"]: mg.agentCode,
        ["code"]: mg.agentCode + "-001",
      }));
    },
    principalOptions() {
      return this.principals.map((mg) => ({
        ["name"]: mg.principalCode,
        ["code"]: mg.principalCode + "-001",
      }));
    },
    dmOptions() {
      return this.districts.map((mg) => ({
        ["name"]: mg.dmCode,
        ["code"]: mg.dmCode + "-001",
      }));
    },
    nsmOptions() {
      return this.nationalSales.map((mg) => ({
        ["name"]: mg.nsmCode,
        ["code"]: mg.nsmCode + "-001",
      }));
    },
  },
  methods: {
    paginationChanged: function (page) {
      if (page == null) {
        page = 1;
      }

      this.salesPageItems = this.salesReports.slice(
        (page - 1) * this.pageSize,
        page * this.pageSize
      );
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    generateReport() {
      this.$toast.open({
        message: "This feature is not yet implemented.",
        type: "info",
      });
    },
    generateInvoiceListingValidator: async function () {
      this.$v.$touch();

      if (!this.$v.invoiceListingReportFilter.$invalid) {
        this.isGenerating = true;

        var result = await this.$store
          .dispatch("reportController/GenerateInvoiceLisingValidation")
          .catch(async (error) => {
            this.isGenerating = false;
            await this.$store.dispatch("showOverlay", false);
            this.$toast.open({
              message: error.data.Message,
              type: "error",
            });
          });

        if (result) {
          this.invoiceListingReportModalShow = false;
          this.isGenerating = false;
          this.value = [];

          this.$toast.open({
            message: "Report has been saved.",
            type: "success",
          });
        }
      }
    },
    generateInvoiceListingReport: async function () {
      this.$v.$touch();

      if (!this.$v.invoiceListingReportFilter.$invalid) {
        this.isGenerating = true;
        this.invoiceListingReportFilter.principalCode = this.value
          .map((mg) => mg.name)
          .join("~");

        var result = false;

        if (this.reportType == "agent") {
          result = await this.$store
            .dispatch("reportController/GenerateAgentInvoiceListing")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "district") {
          result = await this.$store
            .dispatch("reportController/GenerateDMInvoiceListing")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "nationalsales") {
          result = await this.$store
            .dispatch("reportController/GenerateNSMInvoiceListing")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else {
          result = await this.$store
            .dispatch("reportController/GenerateInvoiceListing")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        }

        if (result) {
          this.invoiceListingReportModalShow = false;
          this.reportType = "principal";
          this.isGenerating = false;
          this.value = [];

          this.$toast.open({
            message: "Report has been saved.",
            type: "success",
          });
        }
      }
    },
    generateSalesSummaryReport: async function () {
      this.$v.$touch();

      if (!this.$v.salesSummaryReportFilter.$invalid) {
        this.isGenerating = true;
        this.salesSummaryReportFilter.principalCode = this.value
          .map((mg) => mg.name)
          .join("~");

        var result = false;

        if (this.reportType == "agent") {
          result = await this.$store
            .dispatch("reportController/GenerateAgentSalesSummary")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "district") {
          result = await this.$store
            .dispatch("reportController/GenerateDMSalesSummary")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "nationalsales") {
          result = await this.$store
            .dispatch("reportController/GenerateNSMSalesSummary")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else {
          result = await this.$store
            .dispatch("reportController/GenerateSalesSummary")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        }

        if (result) {
          this.salesSummaryReportModalShow = false;
          this.reportType = "principal";
          this.isGenerating = false;
          this.value = [];

          this.$toast.open({
            message: "Report has been saved.",
            type: "success",
          });
        }
      }
    },
    generateSalesReturnReport: async function () {
      this.$v.$touch();

      if (!this.$v.salesReturnReportFilter.$invalid) {
        this.isGenerating = true;
        this.salesReturnReportFilter.principalCode = this.value
          .map((mg) => mg.name)
          .join("~");

        var result = false;

        if (this.reportType == "agent") {
          result = await this.$store
            .dispatch("reportController/GenerateAgentSalesReturn")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "district") {
          result = await this.$store
            .dispatch("reportController/GenerateDMSalesReturn")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else if (this.reportType == "nationalsales") {
          result = await this.$store
            .dispatch("reportController/GenerateNSMSalesReturn")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        } else {
          result = await this.$store
            .dispatch("reportController/GenerateSalesReturn")
            .catch(async (error) => {
              this.isGenerating = false;
              await this.$store.dispatch("showOverlay", false);
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });
        }

        if (result) {
          this.salesReturnReportModalShow = false;
          this.reportType = "principal";
          this.isGenerating = false;
          this.value = [];

          this.$toast.open({
            message: "Report has been saved.",
            type: "success",
          });
        }
      }
    },
    async uploadFile() {
      await this.$store.dispatch("showOverlay", true);
      if (this.version == "1") {
        await this.uploadV1();
      } else {
        await this.uploadV2();
      }
    },
    async uploadV1() {
      var result = await this.$store
        .dispatch("reportController/UploadSalesReport", this.file)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data.Message,
            type: "error",
          });
        });

      if (result) {
        await this.searchFilter();
        await this.$store.dispatch("showOverlay", false);
        this.uploadModalShow = false;
        this.$toast.open({
          message: "Report has been uploaded.",
          type: "success",
        });
      }
    },
    async uploadV2() {
      var result = await this.$store
        .dispatch("reportController/UploadSalesReportV2", this.file)
        .catch(async (error) => {
          await this.$store.dispatch("showOverlay", false);
          this.$toast.open({
            message: error.data.Message,
            type: "error",
          });
        });

      if (result) {
        await this.searchFilter();
        await this.$store.dispatch("showOverlay", false);
        this.uploadModalShow = false;
        this.$toast.open({
          message: "Report has been uploaded.",
          type: "success",
        });
      }
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.pageOfItems = [];
      this.isSearching = true;
      this.hasNoRecord = false;
      // var result = await this.$store
      //   .dispatch("reportController/GetSalesReports")
      //   .catch((error) => {
      //     this.hasNoRecord = true;
      //     this.isSearching = false;
      //   });

      // if (result) {
      if (true) {
        this.paginationChanged();
        this.isSearching = false;

        if (this.salesReports.length == 0) {
          this.hasNoRecord = true;
        }
      }
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style scoped></style>