import axios from "axios";

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        nationalSales: [],
        nationalSalesManager: {
            nsmID: 0,
            nsmCode: '',
            nsmName: '',
            listAgentCodes: [],
            listEmail: []
        },
        filter: {
            nsmCode: ""
        }
    },
    mutations: {
        SET_NationalSales(state, value) {
            state.nationalSales = value
        },
        SET_NationalSalesManager(state, value) {
            state.nationalSalesManager = Object.assign({}, value);
        },
        RESET_NationalSalesManager(state) {
            state.nationalSalesManager = {
                nsmID: 0,
                nsmCode: '',
                nsmName: '',
                listAgentCodes: [],
                listEmail: []
            }
        }
    },
    getters: {
        filter(state) {
            return state.filter
        },
        nationalSalesManager(state) {
            return state.nationalSalesManager
        },
    },
    actions: {
        GetNationalSales({ getters, commit, rootGetters }) {
            var filter = getters.filter;
            commit('SET_NationalSales', [])

            return new Promise((resolve, reject) => {
                instance
                    .get("api/nationalsales?nsmCode=" + filter.nsmCode, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_NationalSales', response.data)
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        AddNationalSalesManager({ getters, rootGetters }) {
            var nationalSalesManager = getters.nationalSalesManager;
            return new Promise((resolve, reject) => {
                instance
                    .post("api/nationalSales", nationalSalesManager, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        UpdateNationalSalesManager({ getters, rootGetters }) {
            var nationalSalesManager = getters.nationalSalesManager;
            return new Promise((resolve, reject) => {
                instance
                    .put("api/nationalSales/" + nationalSalesManager.nsmid, nationalSalesManager, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        DeleteNationalSalesManager({ getters, rootGetters }) {
            var nationalSalesManager = getters.nationalSalesManager;

            return new Promise((resolve, reject) => {
                instance
                    .delete("api/nationalSales/" + nationalSalesManager.nsmid, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
    },
};