import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vuelidate from 'vuelidate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Multiselect from "vue-multiselect"
import VueToast from 'vue-toast-notification'
import DatePicker from 'vue2-datepicker';

import 'vue2-datepicker/index.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.config.productionTip = false

Vue.use(VueToast, {
    position: 'top-right',
    duration: 0,
    dismissible: true,
    queue: true,
});

Vue.use(DatePicker)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)

Vue.component('multiselect', Multiselect)

new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')