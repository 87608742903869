<template>
  <div class="user-container">
    <div class="row">
      <div class="col-lg-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">User Management</h4>
            <div class="filters">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col">
                      <label>Email:</label>
                      <input
                        type="text"
                        class="form-control mb-2 mr-sm-2"
                        v-model="filter.email"
                        placeholder="Email"
                      />
                    </div>
                    <div class="col">
                      <p style="margin-bottom: 0.3rem">&nbsp;</p>
                      <button
                        type="button"
                        class="btn btn-primary mb-2"
                        @click="searchFilter"
                      >
                        <i class="fas fa-search"></i>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12
                    text-right
                  "
                >
                  <p style="margin-bottom: 0rem">&nbsp;</p>
                  <button
                    type="button"
                    class="
                      btn btn-primary btn-sm-block
                      mt-1
                      ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2
                    "
                    @click="
                      isEdit = false;
                      userModalShow = !userModalShow;
                      $store.dispatch('userController/ResetUser');
                      $v.$reset();
                    "
                  >
                    <i class="fas fa-user-plus"></i>
                    Add User
                  </button>
                </div>
              </div>
              <hr />
              <div class="row mt-4"></div>
            </div>

            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Fullname</th>
                    <th>Username</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="hasNoRecord">
                    <td colspan="7">
                      <div>
                        <lottie-animation
                          style="height: 500px"
                          :loop="true"
                          :animationData="
                            require('@/assets/lottie/no-data.json')
                          "
                        />
                      </div>
                    </td>
                  </tr>
                  <tr v-if="isSearching">
                    <td colspan="7">
                      <div class="text-center">
                        <i class="fas fa-spinner fa-spin mr-1" />
                        <span>Loading. . .</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-for="(user, i) in users" :key="i">
                    <td>{{ user.id }}</td>
                    <td>{{ user.fullname }}</td>
                    <td>{{ user.userName }}</td>
                    <td>
                      <!-- <a
                        href="#"
                        @click="
                          editUser(user);
                          isEdit = true;
                        "
                      >
                        Edit
                      </a> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="userModalShow"
      size="md"
      title="User Details"
      hide-footer
      no-close-on-backdrop
    >
      <form class="forms-sample">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtFirstName">First Name</label>
              <input
                type="text"
                class="form-control"
                id="txtFirstName"
                placeholder="First Name"
                :class="{ 'is-invalid': $v.user.firstName.$error }"
                v-model="$v.user.firstName.$model"
              />
              <div class="form-error" v-if="$v.user.firstName.$error">
                First Name is required.
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="txtLastName">Last Name</label>
              <input
                type="text"
                class="form-control"
                id="txtLastName"
                placeholder="Last Name"
                :class="{ 'is-invalid': $v.user.firstName.$error }"
                v-model="$v.user.lastName.$model"
              />
              <div class="form-error" v-if="$v.user.firstName.$error">
                Last Name is required.
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtEmail">Email Address</label>
              <input
                type="text"
                class="form-control"
                id="txtEmail"
                placeholder="Email Address"
                :class="{
                  'is-invalid': $v.user.email.$error || emailError != '',
                }"
                v-model="$v.user.email.$model"
              />
              <div class="form-error" v-if="$v.user.email.$error">
                Email address must be in valid format.
              </div>
              <div class="form-error" v-if="emailError != ''">
                {{ emailError }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtPassword">Password</label>
              <input
                type="password"
                class="form-control"
                id="txtPassword"
                placeholder="Password"
                :class="{
                  'is-invalid': $v.user.password.$error || passwordError != '',
                }"
                v-model="$v.user.password.$model"
              />
              <div class="form-error" v-if="$v.user.password.$error">
                Password is required.
              </div>
              <div class="form-error" v-if="passwordError != ''">
                {{ passwordError }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="txtConfirmPassword">Confirm Password</label>
              <input
                type="password"
                class="form-control"
                id="txtConfirmPassword"
                placeholder="Confirm Password"
                :class="{ 'is-invalid': $v.user.confirmPassword.$error }"
                v-model="$v.user.confirmPassword.$model"
              />
              <div class="form-error" v-if="$v.user.confirmPassword.$error">
                Passwords must be identical.
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <button
              type="button"
              class="btn btn-primary mr-2"
              @click="submitUser"
            >
              Submit
            </button>
            <button
              type="button"
              class="btn btn-light"
              @click="userModalShow = !userModalShow"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, sameAs, email } from "vuelidate/lib/validators";
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
  data() {
    return {
      userModalShow: false,
      pageSize: 50,
      currentPage: 1,
      pageOfItems: [],
      isSearching: false,
      hasNoRecord: false,
      emailError: "",
      passwordError: "",
      isEdit: false,
    };
  },
  validations: {
    user: {
      email: {
        required,
        email,
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      password: {
        required,
      },
      confirmPassword: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  computed: {
    ...mapState({
      users: (state) => state.userController.users,
      user: (state) => state.userController.user,
      filter: (state) => state.userController.filter,
    }),
  },
  methods: {
    validateEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    searchFilter: async function () {
      this.currentPage = 1;
      this.pageOfItems = [];
      this.isSearching = true;
      this.hasNoRecord = false;
      var result = await this.$store
        .dispatch("userController/GetUsers")
        .catch((error) => {
          this.hasNoRecord = true;
          this.isSearching = false;
        });

      if (result) {
        this.isSearching = false;

        if (this.users.length == 0) {
          this.hasNoRecord = true;
        }
      }
    },
    submitUser: async function () {
      this.passwordError = "";
      this.emailError = "";
      var self = this;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (this.isEdit) {
          // edit existing record
          var result = await this.$store
            .dispatch("userController/EditUser")
            .catch((error) => {
              this.$toast.open({
                message: error.data.Message,
                type: "error",
              });
            });

          if (result) {
            this.$toast.open({
              message: "User has been successfully edited.",
              type: "success",
            });

            this.userModalShow = false;
          }
        } else {
          // insert new record
          var result = await this.$store
            .dispatch("userController/Register")
            .catch((error) => {
              if (error.data[0].includes("Username")) {
                var msg = error.data[0].replace(/Username/g, "Email Address");
                self.emailError = msg;
              } else {
                self.passwordError = error.data[0];
              }
            });

          if (result) {
            this.$toast.open({
              message: "User has been successfully added.",
              type: "success",
            });

            this.userModalShow = false;
          }

          this.$v.$reset();
        }
      }
    },
    editUser: function (user) {
      this.$store.dispatch("userController/BindUser", user);

      this.userModalShow = !this.userModalShow;
    },
  },
  async mounted() {
    await this.searchFilter();
  },
};
</script>

<style>
</style>