import axios from "axios";
import Vue from "vue";

var instance = axios.create({
    baseURL: window.location.origin,
});

export default {
    namespaced: true,
    state: {
        users: [],
        user: {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
        },
        filter: {
            email: '',
        },
        userLogs: [],
        isSearching: false,
        logFilter: {
            dateRange: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
            status: 'All',
        }
    },
    getters: {
        getUser(state) {
            return state.user
        },
        filter(state) {
            return state.filter
        },
        logFilter(state) {
            let yearFrom = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.logFilter.dateRange[0]);
            let monthFrom = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.logFilter.dateRange[0]);
            let dayFrom = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.logFilter.dateRange[0]);
            var dateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;

            let yearTo = new Intl.DateTimeFormat("en", { year: "numeric" }).format(state.logFilter.dateRange[1]);
            let monthTo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(state.logFilter.dateRange[1]);
            let dayTo = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(state.logFilter.dateRange[1]);
            var dateTo = `${yearTo}-${monthTo}-${dayTo}`;

            return {
                dateFrom: dateFrom,
                dateTo: dateTo
            }
        },
    },
    mutations: {
        SET_Users(state, data) {
            state.users = data;
        },
        SET_UserLogs(state, data) {
            if (state.logFilter.status != 'All') {
                state.userLogs = data.filter(m => m.particulars.includes(state.logFilter.status));
            } else {
                state.userLogs = data;
            }
        },
        SET_IsSearching(state, data) {
            state.isSearching = data;
        },
        UPDATE_Users(state, data) {
            var index = state.users.findIndex((obj => obj.email == data.email));

            if (index >= 0) {
                Vue.set(state.users, index, data);
            } else {
                state.users.push(data);
            }
        },
        RESET_User(state) {
            state.user = {
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                confirmPassword: '',
            };
        },
        RESET_Users(state) {
            state.users = [];
        }
    },
    actions: {
        Login({ getters, dispatch }) {
            var user = getters.getUser;
            return new Promise((resolve, reject) => {
                instance
                    .post("api/User/login", user)
                    .then(async function (response) {
                        dispatch("setToken", response.data.accessToken, { root: true }).then(() => { });
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        Register({ getters, commit, rootGetters }) {
            var user = getters.getUser;
            return new Promise((resolve, reject) => {
                instance
                    .post("api/User/register", user, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(function (response) {
                        commit('UPDATE_Users', response.data);
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        GetUsers({ getters, commit, rootGetters }) {
            var filter = getters.filter;
            commit('RESET_Users');

            return new Promise((resolve, reject) => {
                instance
                    .get("api/User?email=" + filter.email, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_Users', response.data)
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        GetUserLogs({ getters, commit, rootGetters }) {
            var logFilter = getters.logFilter;
            commit('SET_IsSearching', true)
            commit('SET_UserLogs', [])

            return new Promise((resolve, reject) => {
                instance
                    .get("api/User/trail?dateFrom=" + logFilter.dateFrom + "&dateTo=" + logFilter.dateTo + "&logType=1", {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                        }
                    })
                    .then(async function (response) {
                        commit('SET_UserLogs', response.data)
                        commit('SET_IsSearching', false)
                        resolve(true);
                    })
                    .catch(function (error) {
                        commit('SET_IsSearching', false)
                        reject(error.response);
                    });
            });
        },
        UploadBINCard({ getters, commit, rootGetters }, payload) {
            let formData = new FormData();
            formData.append('file', payload);

            return new Promise((resolve, reject) => {
                instance
                    .post("api/Upload/deliverymonitoring", formData, {
                        headers: {
                            Authorization: "Bearer " + rootGetters.token,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(async function (response) {
                        resolve(true);
                    })
                    .catch(function (error) {
                        reject(error.response);
                    });
            });
        },
        ResetUser({ commit }) {
            commit('RESET_User')
        }
    },
    modules: {},
};